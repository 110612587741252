import React from 'react';
import { getBackendSrv } from '@grafana/runtime';
import { useAppState } from 'app-context/AppStateContext';
import { MacAddressProps, MachineFilterProps } from 'app-context/types';
import {
  applyFilterDeletion,
  applyNewFilterName,
  applyFilterRestoration,
  changeFilterEditionStatus,
  findFilterName,
  handleMacAddressDragged,
  resetEditionFlags,
  retrieveMachineFilter,
  retrieveOtherTags,
} from 'components/filters/helpersFilters';
import { API_DASHBOARD_BY_UID, API_SAVE_JSON } from 'helpers/URLS';
import { DropResult } from 'react-beautiful-dnd';
import { NotificationError } from 'shared/NotificationMessage';
import { SelectableValue } from '@grafana/data';

export const useFiltersManagement = () => {
  const { state, dispatch } = useAppState();
  const { datasourceJson, dictionary_uiElements, selectedFolder } = state;

  // endpoint
  const WRITE_API_URL = `api/datasources/proxy/uid/${datasourceJson.uid}`;
  const TAGS_ENDPOINT = `${WRITE_API_URL}/identity_card_tags`;

  // traduction
  const { TR_filterAlreadyExists } = dictionary_uiElements;

  const [folders, setFolders] = React.useState<SelectableValue[]>([]);
  const [machineFilters, setMachineFilters] = React.useState<MachineFilterProps[]>([]);
  const [macAddresses, setMacAddresses] = React.useState<MacAddressProps[]>([]);
  const [machinesToDelete, setMachinesToDelete] = React.useState<MachineFilterProps[]>([]);
  const [isLoadingFilters, setIsLoadingFilters] = React.useState(false);
  const [isApplyingChanges, setIsApplyingChanges] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [isEditingFilters, setIsEditingFilters] = React.useState(true);

  /** Retreive folders and dashboards where user has permissions */
  const getUserFolders = async () => {
    setIsLoadingFilters(true);
    setMachineFilters([]);
    setFolders([]);
    setMacAddresses([]);
    dispatch({ type: 'SET_MAC_ADDRESSES', payload: [] });

    await getBackendSrv()
      .get(`/api/search?folderIds=0`) // General folder and all other folders
      .then(async (results) => {
        if (!results) {
          return;
        }
        const folderOptions: SelectableValue[] = [{ label: 'General', value: 0 }];
        const machinesTags: MachineFilterProps[] = [];
        const dashboardsInFolders: MacAddressProps[] = [];

        for (const result of results) {
          if (result.type === 'dash-folder') {
            await getBackendSrv()
              .get(`/api/search?folderIds=${result.id}`)
              .then(async (resultDashboards) => {
                if (resultDashboards.length) {
                  folderOptions.push({ label: result.title, value: result.id });

                  for (const dashboard of resultDashboards) {
                    if (dashboard?.tags.findIndex((tag: string) => tag.toLowerCase() === 'machine') !== -1) {
                      const dashboardInfo = await getBackendSrv()
                        .get(`${API_DASHBOARD_BY_UID}${dashboard.uid}`)
                        .catch((error: any) => {
                          console.log(error);
                          NotificationError(error);
                          setIsLoadingFilters(false);
                          return;
                        });

                      const templating = dashboardInfo?.dashboard.templating.list;
                      if (!templating) {
                        return;
                      }
                      // Get mac_address template
                      const dashboardMacAddress = templating.find(
                        (template: { name: string }) => template.name === 'beacon_selection'
                      );

                      const machineFilter = retrieveMachineFilter(dashboard.tags, machinesTags, result.id);
                      const otherTags = retrieveOtherTags(dashboard.tags);

                      const dashboardObj = {
                        macAddress: dashboardMacAddress?.query,
                        dashboardName: dashboardInfo?.dashboard?.title,
                        folderId: result.id,
                        machineFilterId: machineFilter ? `${machineFilter}-${result.id}` : '',
                        otherTags: otherTags,
                        isTagsModified: false,
                        hasBeenMoved: false,
                        dashboardUid: dashboard.uid,
                      };
                      dashboardsInFolders.push(dashboardObj);
                    }
                  }
                }
              })
              .catch((error) => {
                console.log(error);
                NotificationError(error);
                setIsLoadingFilters(false);
              });
          }
          /**
           * Handle dashboards in general folder (id=0)
           */
          if (result.type === 'dash-db') {
            // only keep dashboards tagged with 'machine' (tag saved in all dashboards via the Wizard Tool)
            if (result?.tags.findIndex((tag: string) => tag.toLowerCase() === 'machine') !== -1) {
              const dashboardInfo = await getBackendSrv()
                .get(`${API_DASHBOARD_BY_UID}${result.uid}`)
                .catch((error: any) => {
                  console.log(error);
                  NotificationError(error);
                  setIsLoadingFilters(false);
                  return;
                });

              const templating = dashboardInfo?.dashboard.templating.list;
              if (!templating) {
                return;
              }
              // Get mac_address template
              const dashboardMacAddress = templating.find(
                (template: { name: string }) => template.name === 'beacon_selection'
              );
              const macAddressTemplate = dashboardMacAddress?.query;
              const machineFilter = retrieveMachineFilter(result?.tags, machinesTags, 0);
              const otherTags = retrieveOtherTags(result?.tags);

              const dashboardObj = {
                macAddress: macAddressTemplate,
                dashboardName: dashboardInfo?.dashboard?.title,
                folderId: 0,
                machineFilterId: machineFilter ? `${machineFilter}-0` : '',
                otherTags: otherTags,
                isTagsModified: false,
                hasBeenMoved: false,
                dashboardUid: result.uid,
              };
              dashboardsInFolders.push(dashboardObj);
            }
          }
        }
        setFolders(folderOptions);
        setMacAddresses(dashboardsInFolders);
        setMachineFilters(machinesTags);
        setIsLoadingFilters(false);
        dispatch({ type: 'SET_MAC_ADDRESSES', payload: dashboardsInFolders });
      })
      .catch((error: any) => {
        console.log(error);
        NotificationError(error);
        setIsLoadingFilters(false);
        return;
      });
  };

  /** Actions on drag end */
  const onDragEnd = (result: DropResult) => {
    const { destination, draggableId, type } = result;
    if (!destination || !draggableId || !result) {
      return;
    }
    const destinationDroppedId = destination.droppableId.split('_');

    if (type === 'MACHINE') {
      /** Handle dragging mac address */
      const macAddressDraggedIndex = macAddresses.findIndex((mac) => mac.macAddress === draggableId);
      if (macAddressDraggedIndex !== -1) {
        const newMacAddresses = handleMacAddressDragged(macAddressDraggedIndex, macAddresses, destinationDroppedId);
        setMacAddresses(newMacAddresses);
        dispatch({ type: 'SET_MAC_ADDRESSES', payload: newMacAddresses });
      }
    }
  };

  /**
   * Switch filter's edition status (boolean).
   */
  const editFilterName = (machineId: string, isEditing: boolean) => {
    const newMachineFilters = changeFilterEditionStatus(machineId, isEditing, machineFilters);
    setMachineFilters(newMachineFilters as MachineFilterProps[]);
  };

  /** Modify filter name */
  const changeFilterName = (filter: MachineFilterProps, value: string) => {
    // Check if tag name already exists in folder
    // const currentFolderIndex = folderTabs.findIndex(tab => tab.active);
    const filtersInFolder = machineFilters.filter((filter) => filter.folderId === selectedFolder.value);
    if (filtersInFolder.findIndex((filter) => filter.name.toLowerCase() === value.toLowerCase()) !== -1) {
      NotificationError({ message: TR_filterAlreadyExists });
      return;
    }
    const newMachinesFilters = applyNewFilterName(filter.id, machineFilters, value);
    setMachineFilters(newMachinesFilters as MachineFilterProps[]);
  };

  /** Delete machine filter */
  const deleteFilter = (filterId: string) => {
    const removeMachineFilterResult = applyFilterDeletion(machineFilters, machinesToDelete, filterId);
    setMachinesToDelete(removeMachineFilterResult.filtersToDelete as MachineFilterProps[]);
    setMachineFilters(removeMachineFilterResult.filters as MachineFilterProps[]);
  };

  /** Restore machine filter */
  const restoreFilter = (filterId: string) => {
    const restoreMachineFilterResult = applyFilterRestoration(machineFilters, machinesToDelete, filterId, folders);
    setMachinesToDelete(restoreMachineFilterResult.filtersToDelete as MachineFilterProps[]);
    setMachineFilters(restoreMachineFilterResult.filters);
  };

  /** Reset changements (request grafana dashboards) */
  const reset = () => {
    getUserFolders();
    setMachinesToDelete([]);
  };

  /** Process all changes on "Apply" click */
  const applyNewFilters = async () => {
    setIsApplyingChanges(true);

    const newMachines = [...machineFilters];
    const newMacAddresses = [...macAddresses];

    /**
     * Update dashboard in db and in Grafana if mac address has been moved
     */
    if (newMacAddresses.length) {
      for (const macAddress of newMacAddresses) {
        const machineIndex = newMachines.findIndex((machine) => machine.id === macAddress.machineFilterId);
        const machineToDeleteIndex = machinesToDelete.findIndex((machine) => machine.id === macAddress.machineFilterId);

        if (!macAddress.hasBeenMoved && !macAddress.isTagsModified) {
          if (machineIndex === -1 && machineToDeleteIndex === -1) {
            continue;
          }
          if (machineToDeleteIndex !== -1 && !machinesToDelete[machineIndex]?.isFilterNameModified) {
            continue;
          }
          if (machineIndex !== -1 && !newMachines[machineIndex]?.isFilterNameModified) {
            continue;
          }
        }

        const machineTagName = findFilterName(macAddress.machineFilterId, newMachines);
        let newDashboardTags = macAddress.otherTags.map((otherTag) => otherTag.label);

        machineTagName !== '' && newDashboardTags.push(machineTagName);
        const TAGS = newDashboardTags.map((tag) => tag).join();

        // Update dasboard in db and grafana
        const tagsPayload = {
          mac_address: macAddress.macAddress,
          tags: TAGS,
        };

        await getBackendSrv()
          .post(TAGS_ENDPOINT, tagsPayload, {
            responseType: 'text',
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then(async () => {
            // Retrieve dashboard and update tags in dashboard JSON
            await getBackendSrv()
              .get(`${API_DASHBOARD_BY_UID}${macAddress.dashboardUid}`)
              .then(async (result) => {
                if (!result) {
                  return;
                }
                let dashboardJSON: any = {};
                dashboardJSON = result;
                dashboardJSON.dashboard.tags = newDashboardTags;
                dashboardJSON.overwrite = true;
                dashboardJSON.folderId = macAddress.folderId;
                await getBackendSrv()
                  .post(API_SAVE_JSON, dashboardJSON)
                  .catch((e: any) => {
                    NotificationError('Error while saving dashboard json');
                    console.log(e);
                  });
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    }
    setIsApplyingChanges(false);
    reset();
  };

  React.useEffect(() => {
    getUserFolders();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!isEditingFilters) {
      const resetMachineEdition = resetEditionFlags(machineFilters);
      setMachineFilters(resetMachineEdition);
    }
  }, [isEditingFilters]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    applyNewFilters,
    changeFilterName,
    deleteFilter,
    editFilterName,
    folders,
    isApplyingChanges,
    isEditingFilters,
    isLoadingFilters,
    macAddresses,
    machineFilters,
    machinesToDelete,
    onDragEnd,
    reset,
    restoreFilter,
    searchValue,
    setIsEditingFilters,
    setMachineFilters,
    setSearchValue,
  };
};
