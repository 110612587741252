import React, { useState, useEffect } from 'react';
import { AlertTableContainer } from './alert-table/AlertTableContainer';
import { css } from '@emotion/css';
import { DashboardWithAlerts } from '../app-context/types';
import { Icon, useStyles2 } from '@grafana/ui';
import { useAppState } from '../app-context/AppStateContext';
import { GrafanaTheme2 } from '@grafana/data';
import { ArrowIcons } from '../shared/ArrowIcons';

interface FolderAndMachineProps {
  selectedDashboards: DashboardWithAlerts[];
  folder: any;
  getTotalDashboardAlerts: (dashboardAlerts: DashboardWithAlerts[], isHeader: boolean) => JSX.Element;
  isUnfold: boolean;
}

export const FoldersAndMachines: React.FunctionComponent<FolderAndMachineProps> = ({
  selectedDashboards,
  folder,
  getTotalDashboardAlerts,
  isUnfold,
}) => {
  const { state } = useAppState();
  const { theme, selectedMachines, machineFilters, dictionary_uiElements, selectedTags } = state;
  const [showFolderContent, setShowFolderContent] = useState(false);
  const { TR_beacon, TR_beacons } = dictionary_uiElements;

  const dashboardsFromFolder = selectedDashboards
    .filter((dashboard) => dashboard.folderId === folder.value)
    .filter((dashboard) => dashboard.alerts.length !== 0);

  const styles = useStyles2(getStyles);

  useEffect(() => {
    setShowFolderContent(isUnfold);
  }, [isUnfold]);

  return (
    <>
      {dashboardsFromFolder.length !== 0 && (
        <div className={styles.mainContainer}>
          <div className={styles.folderNameContainer}>
            <div className={styles.foldUnfold} onClick={() => setShowFolderContent(!showFolderContent)}>
              <ArrowIcons isOpen={showFolderContent} />
              <div style={{ marginRight: '5px' }}>
                {showFolderContent ? <Icon name={'folder-open'} size={'lg'} /> : <Icon name={'folder'} size={'lg'} />}
              </div>
              <div className={styles.folderTitle}>{folder.label?.toUpperCase()}</div>
              <div className={styles.totalDashboards}>
                {dashboardsFromFolder.length}{' '}
                {dashboardsFromFolder.length > 1 ? TR_beacons?.toLowerCase() : TR_beacon?.toLowerCase()}
              </div>
            </div>
            {/* Display total of alerts zones only if zone is fold */}
            {!showFolderContent && getTotalDashboardAlerts(dashboardsFromFolder, false)}
          </div>
          {showFolderContent &&
            selectedMachines.length !== 0 &&
            selectedMachines.map((machine, index) => (
              <MachineFilter
                selectedDashboards={dashboardsFromFolder}
                machineFilter={machine}
                getTotalDashboardAlerts={getTotalDashboardAlerts}
                theme={theme}
                isUnfold={isUnfold}
                key={`${machine.value}-${index}`}
              />
            ))}
          {showFolderContent && !selectedMachines.length && selectedTags.length !== 0 && (
            <div className={styles.tableContainer}>
              <AlertTableContainer selectedDashboards={dashboardsFromFolder} />
            </div>
          )}
          {showFolderContent &&
            !selectedMachines.length &&
            !selectedTags.length &&
            machineFilters.map((machine, index) => (
              <MachineFilter
                key={`${machine.value}-${index}`}
                selectedDashboards={dashboardsFromFolder}
                machineFilter={machine}
                getTotalDashboardAlerts={getTotalDashboardAlerts}
                theme={theme}
                isUnfold={isUnfold}
              />
            ))}
          {showFolderContent && !selectedMachines.length && !selectedTags.length && (
            <MachineFilter
              selectedDashboards={dashboardsFromFolder}
              machineFilter={{ label: 'no_machine_filter', value: '' }}
              getTotalDashboardAlerts={getTotalDashboardAlerts}
              theme={theme}
              isUnfold={isUnfold}
            />
          )}
        </div>
      )}
    </>
  );
};

interface MachineProps {
  selectedDashboards: DashboardWithAlerts[];
  theme: GrafanaTheme2;
  getTotalDashboardAlerts: (dashboardAlerts: DashboardWithAlerts[], isHeader: boolean) => JSX.Element;
  machineFilter: {
    value: string;
    label: string;
  };
  isUnfold: boolean;
}

export const MachineFilter: React.FunctionComponent<MachineProps> = ({
  selectedDashboards,
  theme,
  machineFilter,
  getTotalDashboardAlerts,
  isUnfold,
}) => {
  const styles = useStyles2(getStyles);
  const [showMachineContent, setShowMachineContent] = useState(false);

  const dashboardsWithMachines = selectedDashboards
    ?.filter((dashboard) => dashboard.machineId === machineFilter?.value)
    .filter((dashboard) => dashboard.alerts.length !== 0);

  useEffect(() => {
    setShowMachineContent(isUnfold);
  }, [isUnfold]);

  return (
    <>
      {dashboardsWithMachines.length !== 0 && (
        <div className={styles.machineContainer}>
          <div className={styles.machineNameContainer}>
            <div className={styles.foldUnfold} onClick={() => setShowMachineContent(!showMachineContent)}>
              <div
                className={css`
                  display: flex;
                  margin-right: 5px;
                `}>
                <ArrowIcons isOpen={showMachineContent} />
                <Icon name={'cog'} size={'md'} />
              </div>
              <div className={styles.machineTitle}>{machineFilter.label?.toUpperCase()}</div>
            </div>
            <div>{getTotalDashboardAlerts(dashboardsWithMachines, false)}</div>
          </div>
          {showMachineContent && (
            <div className={styles.tableContainer}>
              <AlertTableContainer selectedDashboards={dashboardsWithMachines} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      margin-bottom: 8px;
    `,
    folderNameContainer: css`
      align-items: center;
      background-color: ${theme.colors.background.secondary};
      border-radius: 2px;
      display: flex;
      height: 52px;
      justify-content: space-between;
      padding: 8px;
      width: 100%;
    `,
    machineContainer: css`
      display: flex;
      width: 98%;
      margin-top: 8px;
      flex-direction: column;
      border-radius: 2px;
    `,
    machineNameContainer: css`
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      background-color: ${theme.isDark ? '#424345' : '#ececec'};
      padding: 6px;
    `,
    foldUnfold: css`
      display: flex;
      align-items: center;
      cursor: pointer;
      min-width: 250px;
    `,
    machineTitle: css`
      width: 100%;
      font-size: 16px;
      font-weight: 500;
    `,
    folderTitle: css`
      font-size: 18px;
      font-weight: 500;
    `,
    totalDashboards: css`
      margin-left: 8px;
      font-size: 12px;
      display: inline;
      align-items: center;
      @media (max-width: 861px) {
        display: none;
      }
    `,
    tableContainer: css`
      width: 100%;
      margin-bottom: 6px;
      background-color: ${theme.isDark ? '#424345' : '#ececec'};
      padding: 16px;
    `,
  };
};
