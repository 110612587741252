import * as React from 'react';
import { FilterEdition } from '.';
import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { MachineFilterProps } from '../../app-context/types';

interface FilterTitleProps {
  filter: MachineFilterProps;
  editFilterName: (filterId: string, isEditing: boolean) => void;
  isEditingFilter: boolean;
  changeFilterName: (filter: MachineFilterProps, value: string) => void;
  getEditButton: (filterId: string) => JSX.Element;
  getDeleteButton: (filterId: string) => JSX.Element | undefined;
}

export const FilterTitle: React.FunctionComponent<FilterTitleProps> = ({
  changeFilterName,
  editFilterName,
  filter,
  isEditingFilter,
  getEditButton,
  getDeleteButton,
}) => {
  const styles = useStyles2(getStyles);

  return (
    <>
      {filter.isEditing ? (
        <FilterEdition
          changeFilterName={changeFilterName}
          defaultValue={filter.name}
          editFilterName={editFilterName}
          filter={filter}
        />
      ) : (
        <>
          <div className={styles.title}>
            {/* Machine filter name */}
            {filter.name}
          </div>
          <div style={{ display: 'flex' }}>
            {/* Edit machine filter name  */}
            {isEditingFilter && getEditButton(filter.id)}
            {/* Delete machine filter (if empty) */}
            {isEditingFilter && getDeleteButton(filter.id)}
          </div>
        </>
      )}
    </>
  );
};

const getStyles = () => {
  return {
    title: css`
      align-items: center;
      display: flex;
      overflow: hidden;
      padding: 4px;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-left: 8px;
    `,
  };
};
