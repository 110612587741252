import React, { useEffect, useState } from 'react';
import { useStyles2, Icon } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useAppState } from '../../app-context/AppStateContext';
import { getStateDisplayModel } from 'helpers/helpersFilters';
import { LastAlertReceivedProps } from 'app-context/types';
import { fr, enGB } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';

interface AlertCardContentProps {
  lastAlert: LastAlertReceivedProps;
  alertIndex: number;
  openModal: (cell: any) => void;
  setCellInfo: (value: React.SetStateAction<{ details: {}; cellIndex: number }>) => void;
}

export const AlertCardContent: React.FunctionComponent<AlertCardContentProps> = ({
  lastAlert,
  alertIndex,
  openModal,
  setCellInfo,
}) => {
  const { state } = useAppState();
  const { theme, lang, dictionary_uiElements } = state;
  const { TR_for, TR_noAlert } = dictionary_uiElements;
  const [alertinfo, setAlertInfo] = useState({});
  const alertStatus = lastAlert?.status;
  const alertName = lastAlert?.name;
  const alertMachineName = lastAlert?.machine;
  const alertDashboardName = lastAlert?.dashboardName;
  const alertLinkToDashboard = lastAlert?.dashboardUrl;
  const alertLinkToPanel = lastAlert?.url;
  const alertNewStateDate = lastAlert?.newStateDate;
  const alertMacAddress = lastAlert?.macAddress;
  const alertDashboardId = lastAlert?.dashboardId;
  const alertPanelId = lastAlert?.panelId;
  const alertId = lastAlert?.alertId;

  const localTime = lang === 'EN' ? { locale: enGB } : { locale: fr };
  const styles = useStyles2(getStyles);

  const getCellInfo = () => {
    /** As alerts can have multiple values, pass this array of values stringified */
    const value =
      alertStatus === 'alerting' || alertStatus === 'pending'
        ? lastAlert && lastAlert?.value && JSON.stringify(lastAlert?.value)
        : lastAlert && lastAlert?.alertValue;

    return {
      value: [
        value,
        alertStatus,
        alertLinkToPanel,
        formatDistanceToNow(new Date(alertNewStateDate), localTime),
        alertPanelId,
        alertId,
      ],
      row: {
        values: {
          dashboardName: [alertDashboardName, alertLinkToDashboard, alertMacAddress, 3, alertDashboardId],
        },
        allCells: [
          { value: [alertDashboardName, alertLinkToDashboard, alertMacAddress, 3, alertDashboardId, alertId] },
        ],
      },
      column: {
        Header: alertName,
      },
    };
  };

  useEffect(() => {
    if (lastAlert) {
      const newLastAlert = getCellInfo();
      setCellInfo({ details: newLastAlert, cellIndex: alertIndex });
      setAlertInfo(newLastAlert);
    }
  }, [lastAlert, alertIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {lastAlert ? (
        <div className={styles.cardContent} onClick={() => openModal(alertinfo)}>
          {/* Icon status */}
          <div
            className={`${styles.iconContainer} ${css`
              background-color: ${getStateDisplayModel(alertStatus, theme).bgColor};
            `}`}>
            <Icon name={getStateDisplayModel(alertStatus).iconClass} size={'xxl'} style={{ color: 'white' }} />
          </div>
          <div
            className={`${styles.centralPart} ${css`
              border: 3px solid ${getStateDisplayModel(alertStatus).bgColor};
            `}`}>
            <div className={styles.alertDetailsLine}>
              {/* Alert name, Dashboard Title + mac address + icone machine*/}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  className={`${styles.alertTitle}  ${css`
                    color: ${getStateDisplayModel(alertStatus, theme).txtColor};
                  `}`}>
                  {alertName}: {alertDashboardName}
                </div>
              </div>
            </div>
            <div
              className={css`
                font-weight: 500;
                font-size: 16px;
                color: ${getStateDisplayModel(alertStatus).txtColor};
              `}>
              {alertStatus}{' '}
              {alertStatus !== 'paused' &&
                alertStatus !== 'unknown' &&
                `${TR_for} ${formatDistanceToNow(new Date(alertNewStateDate), localTime)}`}
            </div>
            <div style={{ marginTop: '9px' }}>
              {alertMachineName && (
                <span
                  className={`${styles.badge} ${css`
                    background-color: ${getStateDisplayModel(alertStatus).bgColorLight};
                    font-weight: 500;
                  `} `}>
                  <Icon name={'cog'} style={{ marginRight: '3px' }} />
                  {alertMachineName}
                </span>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.cardContentNoAlert}>
          <div className={styles.noAlert}>{TR_noAlert}</div>
        </div>
      )}
    </>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    alertTitle: css`
      font-size: 18px;
      font-weight: 600;
      padding-right: 5px;
      cursor: pointer;
      max-width: 450px;
      overflow: hidden;
      max-width: 374px;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    badge: css`
      font-size: 14px;
      display: inline-flex;
      padding: 4px 6px;
      border-radius: 4px;
      display: inline;
      margin-top: 5px;
      @media (max-width: 861px) {
        display: none;
      }
    `,
    iconContainer: css`
      padding: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
    `,
    centralPart: css`
      display: flex;
      flex-direction: column;
      padding: 8px 18px;
      width: 100%;
      color: ${'#424345'};
    `,
    cardContent: css`
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 100%;
    `,
    cardContentNoAlert: css`
      display: flex;
      width: 100%;
      border: 1px solid ${theme.colors.border.medium};
    `,
    noAlert: css`
      height: 58px;
      font-style: italic;
      padding: 8px;
      text-align: center;
      width: 100%;
    `,
    alertDetailsLine: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: start;
      flex-wrap: wrap;
    `,
  };
};
