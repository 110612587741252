import React from 'react';
import { css } from '@emotion/css';
import { StatusType } from '../../app-context/types';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from '../../app-context/AppStateContext';
import { getStateDisplayModel } from '../../helpers/helpersFilters';

interface AlertSelectStatusProps {}

export const AlertSelectStatus: React.FunctionComponent<AlertSelectStatusProps> = () => {
  const { state, dispatch } = useAppState();
  const { selectedStatus, theme } = state;

  const styles = useStyles2(getStyles);

  /**
   * Handle alert select status
   */
  const onSelectStatus = (status: StatusType) => {
    switch (status) {
      case StatusType.ok:
        dispatch({
          type: 'SET_SELECTED_STATUS',
          payload: { ...selectedStatus, ok: !selectedStatus.ok },
        });
        break;
      case StatusType.alerting:
        dispatch({
          type: 'SET_SELECTED_STATUS',
          payload: { ...selectedStatus, alerting: !selectedStatus.alerting },
        });
        break;
      case StatusType.noData:
        dispatch({
          type: 'SET_SELECTED_STATUS',
          payload: { ...selectedStatus, noData: !selectedStatus.noData },
        });
        break;
      case StatusType.paused:
        dispatch({
          type: 'SET_SELECTED_STATUS',
          payload: { ...selectedStatus, paused: !selectedStatus.paused },
        });
        break;
      default:
        break;
    }
  };

  const getAlertStateSelection = () => {
    const alertStates = [
      {
        isSelected: selectedStatus.alerting,
        text: 'ALERTING',
        status: StatusType.alerting,
      },
      {
        isSelected: selectedStatus.noData,
        text: 'NO DATA',
        status: StatusType.noData,
      },
      {
        isSelected: selectedStatus.ok,
        text: 'OK',
        status: StatusType.ok,
      },
      {
        isSelected: selectedStatus.paused,
        text: 'PAUSED',
        status: StatusType.paused,
      },
    ];

    return alertStates.map((alertState, index) => (
      <div
        key={`${alertState.text}-${index}`}
        className={styles.selectButton}
        style={{
          backgroundColor: alertState.isSelected ? getStateDisplayModel(alertState?.status).bgColorLight : '',
          color: alertState.isSelected ? (theme.isDark ? '#000000' : '') : '',
          fontWeight: alertState.isSelected ? 500 : 0,
        }}
        onClick={() => onSelectStatus(alertState?.status)}>
        {alertState.text}
      </div>
    ));
  };

  return <div className={styles.wrapper}>{getAlertStateSelection()}</div>;
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      border-radius: 2px;
      border: 1px solid ${theme.colors.border.medium};
      display: flex;
      padding: 2px;
      width: 100%;
      flex-wrap: wrap;
    `,
    selectButton: css`
      border-radius: 2px;
      cursor: pointer;
      flex: 1;
      margin: 1px;
      padding: 2px 21px;
      text-align: center;
      font-size: 12px;
      white-space: nowrap;
    `,
  };
};
