import * as React from 'react';
import { Icon, Button } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';

interface AddMachineButtonProps {
  setIsAddingMachineFilter: (isAdding: boolean) => void;
}

export const AddMachineButton: React.FunctionComponent<AddMachineButtonProps> = ({ setIsAddingMachineFilter }) => {
  const { state } = useAppState();
  const { dictionary_uiElements } = state;
  const { TR_createMachineFilter } = dictionary_uiElements;
  return (
    <Button
      onClick={() => setIsAddingMachineFilter && setIsAddingMachineFilter(true)}
      size={'lg'}
      variant={'secondary'}
      fill={'text'}>
      <Icon name={'plus'} />
      {'  '}
      {TR_createMachineFilter?.toUpperCase()}
    </Button>
  );
};
