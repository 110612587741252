import { GrafanaTheme2, DataSourceApi, SelectableValue, PanelData } from '@grafana/data';
import { TranslationProps } from './translation-types';
export interface AppStateContextProps {
  state: AppState;
  dispatch: React.Dispatch<Action>;
}

export type Action =
  | {
      type: 'SET_DATASOURCES';
      payload: {
        influx: any;
        mysql: any;
        json: any;
      };
    }
  | {
      type: 'SET_DASHBOARDS_WITH_ALERTS';
      payload: DashboardWithAlerts[];
    }
  | {
      type: 'SET_SELECTED_DASHBOARDS';
      payload: DashboardWithAlerts[];
    }
  | {
      type: 'SET_TAGS';
      payload: FilterProps[];
    }
  | {
      type: 'SET_SELECTED_TAGS';
      payload: FilterProps[];
    }
  | {
      type: 'SET_MACHINE_FILTERS';
      payload: FilterProps[];
    }
  | {
      type: 'SET_SELECTED_FOLDERS';
      payload: FilterProps[];
    }
  | {
      type: 'SET_SELECTED_STATUS';
      payload: SelectStatusProps;
    }
  | {
      type: 'SET_SELECTED_MACHINES';
      payload: FilterProps[];
    }
  | {
      type: 'ADD_TRANSLATED_UI_TO_DICO';
      payload: TranslationProps;
    }
  | {
      type: 'SET_ALERTS_COLUMNS';
      payload: AlertsColumnProps[];
    }
  | {
      type: 'SET_MAC_ADDRESSES';
      payload: MacAddressProps[];
    }
  | {
      type: 'SET_SELECTED_FOLDER';
      payload: SelectableValue;
    }
  | {
      type: 'SET_PAUSE_TRIGGER';
      payload: number;
    };

export interface MainContainerProps {
  data: PanelData;
}

export interface AppState {
  alertsColumns: AlertsColumnProps[];
  dashboardsWithAlerts: DashboardWithAlerts[];
  datasourceInflux: DataSourceApi;
  datasourceMysql: DataSourceApi;
  datasourceJson: DataSourceApi;
  dictionary_uiElements: TranslationProps;
  lang: string;
  machineFilters: FilterProps[];
  selectedDashboards: DashboardWithAlerts[];
  selectedMachines: FilterProps[];
  selectedTags: FilterProps[];
  selectedFolders: FilterProps[];
  selectedStatus: SelectStatusProps;
  tags: FilterProps[];
  theme: GrafanaTheme2;
  user: GrafanaUserProps;
  macAddresses: MacAddressProps[];
  selectedFolder: SelectableValue;
  pauseTrigger: number;
}

export type GrafanaUserProps = {
  isGrafanaAdmin: boolean;
  orgName: string;
  orgRole: string;
  orgId: number;
  timeZone: string;
  name: string;
};

export type AlertReceivedProps = {
  id: number;
  name: string;
  dashboardUid: string;
  dashboardId: number;
  dashboardSlug: string;
  evalData: {
    evalMatches: any[];
  };
  panelId: number;
  state: string;
  newStateDate: Date;
  url: string;
};

export interface FilterProps<T = any> {
  // count: number;
  label: string;
  value: T;
}

export type AlertProps = {
  alertId: number;
  dashboardId: number;
  dashboardSlug: string;
  dashboardUid: string;
  dashboardUrl: string;
  name: string;
  newStateDate: string;
  panelId: number;
  status: string;
  url: string;
  value: AlertMetricValue[];
  alertValue: string;
};

export type AlertMetricValue = {
  metric: string;
  value: number;
};

export type QueryProps = {
  datasourceId: any;
  format: string;
  rawSql: string;
  refId: string;
};

export enum TemplatesVar {
  lang = 'lang_selection',
  macAddress = 'beacon_selection',
  image = 'imageFile',
  lengthUnit = 'length_unit',
}

export enum StatusType {
  alerting = 'alerting',
  noData = 'no_data',
  unknown = 'unknown',
  ok = 'ok',
  paused = 'paused',
}

export interface SelectStatusProps {
  alerting: boolean;
  noData: boolean;
  ok: boolean;
  paused: boolean;
  [key: string]: boolean;
}

export type DashboardWithAlerts = {
  alerts: AlertProps[];
  dashboardImage: string;
  dashboardMacAddress: string;
  dashboardModel: any;
  dashboardTags: string[];
  dashboardTitle: string;
  dashboardUid: string;
  dashboardUrl: string;
  dashboardId: number;
  folderTitle: string;
  folderId: number;
  locationId: number;
  machineId: string;
  lengthUnit: string;
  processFunction: string;
  countAlerts: {
    ok: number;
    noData: number;
    paused: number;
    alerting: number;
    unknown: number;
  };
};

export interface AlertsColumnProps {
  Header: string;
  accessor: string;
  isChecked: boolean;
}

export type StrategyNames = 'strategy-0x101' | 'strategy-0x102' | 'strategy-0x201' | 'strategy-0x202';

export interface LastAlertReceivedProps extends AlertProps {
  dashboardName: string;
  macAddress: string;
  machine: string;
}

export interface Option {
  label: string;
  value: string | number;
}

export interface MachineFilterProps {
  name: string;
  id: string;
  isEditing: boolean;
  isAddingFilter?: boolean;
  isFilterNameModified: boolean;
  folderId: number;
}

export interface MacAddressProps {
  macAddress: string;
  folderId: number;
  machineFilterId: string;
  dashboardName: string;
  hasBeenMoved: boolean;
  dashboardUid: string;
  otherTags: Option[];
  isTagsModified: boolean;
}

export enum FilterType {
  LOCATION = 'LOCATION',
  MACHINE = 'MACHINE',
}

export interface AnnotationProps {
  created: string;
  text: string;
  time: string;
  login: string;
}

export interface AnomalyCause {
  id: string;
  texts: string[];
}

export interface AnomalyContentProps {
  name: string;
  class: string;
  queryName: string;
  isFront: boolean;
  content: {
    symptoms: string[];
    causes: AnomalyCause[];
  };
}
