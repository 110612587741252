import React from 'react';
import { useStyles2, Icon, Button, Modal, Badge } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useAppState } from '../../app-context/AppStateContext';
import { NotificationError } from 'shared/NotificationMessage';
import { getSyntheticDashboardJsonModel, saveDashboardJsonModel } from 'helpers/requests';
import { getSelectedFiltersInfo } from 'helpers/helpersFilters';

interface ModalSaveParametersProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
}

export const ModalSaveParameters: React.FunctionComponent<ModalSaveParametersProps> = ({
  isModalVisible,
  setIsModalVisible,
}) => {
  const { state } = useAppState();
  const { selectedFolders, selectedTags, selectedMachines, selectedStatus, dictionary_uiElements } = state;
  /**UI translation */
  const { TR_saveParameters, TR_cancel, TR_reloadParametersText } = dictionary_uiElements;
  const styles = useStyles2(getStyles);
  const filters = getSelectedFiltersInfo(
    selectedStatus,
    selectedFolders,
    selectedMachines,
    selectedTags,
    dictionary_uiElements
  );

  const saveFiltersSelection = async () => {
    const url = window.location.href.split('/');
    const uidIndex = url.indexOf('d');
    const uid = url[uidIndex + 1];

    if (uidIndex === -1) {
      NotificationError({ message: 'Please, save dashboard first.' });
    }

    const currentDashboard = await getSyntheticDashboardJsonModel(uid);

    const updatedList = currentDashboard.templating.list.filter(
      (template: any) => template.name === 'lang_selection' || template.name === 'mysql' || template.name === 'influx'
    );
    const tagSelectionReset = {
      hide: 2,
      label: '',
      name: 'tag_selection',
      query: '',
      skipUrlSync: false,
      type: 'constant',
    };

    const formatVariables = filters.map((variable) => ({
      current: { selected: false, text: variable.varValues, value: variable.varValues },
      hide: 2,
      includeAll: false,
      label: variable.varLabel,
      multi: false,
      name: variable.varName,
      options: [],
      query: variable.varValues,
      skipUrlSync: false,
      type: 'custom',
    }));

    const dashboardNewVariables = [...updatedList, tagSelectionReset, ...formatVariables];
    currentDashboard.templating.list = dashboardNewVariables;

    const jsonDash = {
      dashboard: {
        // Copy the rest of the dashboard properties
        ...currentDashboard,
      },
      overwrite: false,
    };
    saveDashboardJsonModel(jsonDash);
  };

  return (
    <Modal
      title={
        <div className="modal-header-title">
          <span className="p-l-1">{TR_saveParameters}</span>
        </div>
      }
      isOpen={isModalVisible}
      onDismiss={() => setIsModalVisible(false)}>
      <div className={styles.containerMsgSave}>
        <div>
          <div className={styles.titleReload}>{TR_reloadParametersText}</div>
          {/* List of used filters */}
          {filters?.map(
            (filter) =>
              filter.selection.length !== 0 && (
                <div className={styles.labelSelect} key={filter.varName}>
                  <div style={{ minWidth: '100px', flex: 1, padding: '8px' }}>{filter.text}</div>
                  <div>
                    {filter.selection.map((folder) => (
                      <span
                        className={css`
                          margin-right: 4px;
                        `}
                        key={folder.label}>
                        <Badge text={folder.label} color={'blue'} icon={filter.icon} />
                      </span>
                    ))}
                  </div>
                </div>
              )
          )}
        </div>
        <div className={styles.containerButtons}>
          <div>
            <Button onClick={() => setIsModalVisible(false)} size="md" variant="secondary">
              <Icon name="times" /> {TR_cancel?.toUpperCase()}
            </Button>
          </div>
          <div style={{ marginRight: '15px' }}>
            <Button onClick={() => saveFiltersSelection()} size="md" variant="primary">
              <Icon name="save" /> {TR_saveParameters?.toUpperCase()}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    labelSelect: css`
      font-weight: 500;
      display: flex;
      margin-bottom: 6px;
      justify-content: space-between;
      align-items: center;
      background-color: ${theme.colors.background.secondary};
    `,
    containerMsgSave: css`
      display: flex;
      flex-direction: column;
      height: 50vh;
      align-items: center;
      justify-content: space-between;
    `,
    containerButtons: css`
      display: flex;
      justify-content: space-between;
      margin-top: 25px;
      width: 100%;
    `,
    filtersTitle: css`
      font-size: 16px;
      font-weight: 500;
      padding: 4px;
      cursor: pointer;
    `,
    badge: css`
      font-size: 12px;
      display: inline-flex;
      padding: 1px 4px;
      border-radius: 3px;
      background: #e5eefd;
      border: 1px solid #9ec1f7;
      color: #093374;
    `,
    titleReload: css`
      margin-bottom: 48px;
      font-size: 18px;
      font-weight: 500;
    `,
  };
};
