import * as React from 'react';
import { Icon, IconName, Drawer } from '@grafana/ui';
import { getStateDisplayModel } from '../../helpers/helpersFilters';
import { fr, enGB } from 'date-fns/locale';
import { formatDistanceToNow } from 'date-fns';
import { useAppState } from '../../app-context/AppStateContext';
import { css } from '@emotion/css';
import { LastAlertReceivedProps } from '../../app-context/types';

interface LastAlertsListDrawerProps {
  alertNewStateDate: string;
  lastAlerts: LastAlertReceivedProps[];
  setIsModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LastAlertsListDrawer: React.FunctionComponent<LastAlertsListDrawerProps> = ({
  lastAlerts,
  setIsModalVisible,
  alertNewStateDate,
}) => {
  const { state } = useAppState();
  const { lang, user, dictionary_uiElements } = state;
  const { TR_lastAlerts, TR_for } = dictionary_uiElements;

  const localTime = lang === 'EN' ? { locale: enGB } : { locale: fr };
  const newDateNewStateDate = lastAlerts && new Date(alertNewStateDate);
  const drawerStyle = `
display:flex; 
flex-direction:column; 
max-height:76vh; 
overflow:auto;
width:100%;
`;
  return (
    <Drawer title={TR_lastAlerts} onClose={() => setIsModalVisible(false)} inline>
      <div
        className={css`
          ${drawerStyle}
        `}>
        <ol className="alert-rule-list">
          {lastAlerts?.map((alert) => (
            <li className="alert-rule-item" key={alert.url}>
              <div
                className={`alert-rule-item__icon ${getStateDisplayModel(alert?.status).stateClass}`}
                style={{ color: `${getStateDisplayModel(alert?.status).bgColor}` }}>
                <Icon
                  name={getStateDisplayModel(alert?.status).iconClass as IconName}
                  size={'xl'}
                  style={{ marginRight: '4px' }}></Icon>
              </div>
              <div className="alert-rule-item__body">
                <div className="alert-rule-item__header">
                  {user.orgRole !== 'Viewer' && (
                    <p className="alert-rule-item__name">
                      <a
                        href={`${alert.url}?editPanel=${alert.panelId}&tab=alert`}
                        target="_blank"
                        rel="noreferrer noopener">
                        {alert.name}
                      </a>
                    </p>
                  )}
                  {user.orgRole === 'Viewer' && (
                    <p className="alert-rule-item__name">
                      <a
                        href={`${alert.url}?viewPanel=${alert.panelId}&tab=alert`}
                        target="_blank"
                        rel="noreferrer noopener">
                        {alert.name}
                      </a>
                    </p>
                  )}
                  <span>{alert.dashboardName}</span>
                  <div className="alert-rule-item__text">
                    <span
                      className={getStateDisplayModel(alert?.status).stateClass}
                      style={{ color: `${getStateDisplayModel(alert?.status).bgColor}` }}>
                      {getStateDisplayModel(alert?.status).text}
                    </span>
                    {alert?.status !== 'paused' && (
                      <span className="alert-rule-item__time">
                        {' '}
                        {TR_for} {formatDistanceToNow(newDateNewStateDate, localTime)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </Drawer>
  );
};
