import { getBackendSrv } from '@grafana/runtime';
import { API_DASHBOARD_BY_UID } from './URLS';
import { NotificationError } from '../shared/NotificationMessage';
import { TemplatesVar } from '../app-context/types';
import { PREFIX_MACHINE_FILTER } from './constants';

interface DashboardInfoProps {
  dashboardImage: string;
  dashboardMacAddress: string;
  dashboardModel: any;
  dashboardName: string;
  dashboardTags: string[];
  dashboardUrl: string;
  folderId: number;
  folderTitle: string;
  machineId: string;
  dashboardId: number;
  lengthUnit: string;
  processFunction: string;
}

export const getDashboardInfo = async (dashboardUid: string, datasourceId: number) => {
  const dashboardInfo = await getBackendSrv()
    .get(`${API_DASHBOARD_BY_UID}${dashboardUid}`)
    .catch((error: any) => {
      console.log(error);
      NotificationError(error);
      return;
    });

  const templating = dashboardInfo?.dashboard?.templating?.list;
  if (!templating) {
    return;
  }

  // Get mac_address template
  const dashboardMacAddress = templating.find(
    (template: { name: string }) => template.name === TemplatesVar.macAddress
  );

  const dashboardImage = templating.find((template: { name: string }) => template.name === TemplatesVar.image);

  let machineFilter = '';
  for (const tag of dashboardInfo?.dashboard?.tags) {
    if (tag.includes(PREFIX_MACHINE_FILTER)) {
      const splitTag = tag.split('_');
      machineFilter = splitTag[1];
    }
  }

  let lengthUnit = '';
  const lengthUnitVariable = dashboardInfo.dashboard.templating.list?.filter(
    (template: { name: TemplatesVar }) => template.name === TemplatesVar.lengthUnit
  );

  if (lengthUnitVariable.length) {
    lengthUnit = lengthUnitVariable[0].query;
  } else {
    lengthUnit = 'meter';
  }

  const indexOfIncidentAdvisorPanel = dashboardInfo.dashboard.panels.findIndex(
    (panel: { type: string }) => panel.type === 'asystom-ad-ia'
  );
  let processFunction = '';

  if (indexOfIncidentAdvisorPanel !== -1) {
    const processFunctionIndex = dashboardInfo.dashboard.panels[indexOfIncidentAdvisorPanel].idCardElements.findIndex(
      (idCardElement: { field: string }) => idCardElement.field === 'process_function'
    );
    if (processFunctionIndex !== -1) {
      processFunction =
        dashboardInfo.dashboard.panels[indexOfIncidentAdvisorPanel].idCardElements[
          processFunctionIndex
        ].value?.toLowerCase();
    }
  }

  const dashboardObj = {
    dashboardName: dashboardInfo?.dashboard?.title,
    dashboardTags: dashboardInfo?.dashboard?.tags,
    folderTitle: dashboardInfo?.meta?.folderTitle,
    folderId: dashboardInfo?.meta?.folderId,
    dashboardMacAddress: dashboardMacAddress?.query,
    dashboardUrl: dashboardInfo?.meta.url,
    machineId: machineFilter,
    dashboardModel: dashboardInfo?.dashboard,
    dashboardImage: dashboardImage?.query,
    dashboardId: dashboardInfo?.dashboard?.id,
    lengthUnit: lengthUnit,
    processFunction: processFunction,
  };

  return dashboardObj as DashboardInfoProps;
};
