import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import React from 'react';
import { css } from '@emotion/css';
import { getBackendSrv } from '@grafana/runtime';

interface AlertInfoNavigationButtonsProps {
  cellInfo: {
    value: any[];
    row: { values: { dashboardName: any[] } };
  };
}

/** Navigation buttons */
export const AlertDetailsNavigationButtons: React.FunctionComponent<AlertInfoNavigationButtonsProps> = ({
  cellInfo,
}) => {
  const { state, dispatch } = useAppState();
  const { dictionary_uiElements, pauseTrigger } = state;
  const { TR_goToDashboard, TR_goToPanel, TR_pauseAlert, TR_unpauseAlert } = dictionary_uiElements;
  const styles = useStyles2(getStyles);

  const alertStatus = React.useMemo(() => (cellInfo?.value.length ? cellInfo?.value[1] : ''), [cellInfo]);
  const linkToPanel = React.useMemo(() => (cellInfo?.value.length ? cellInfo?.value[2] : ''), [cellInfo]);
  const alertId = React.useMemo(() => (cellInfo?.value.length ? cellInfo?.value[5] : ''), [cellInfo]);
  const linkToDashboard = React.useMemo(
    () => (cellInfo?.row?.values?.dashboardName.length ? cellInfo?.row?.values?.dashboardName[1] : ''),
    [cellInfo]
  );

  const buttons = [
    {
      link: linkToPanel,
      text: TR_goToPanel?.toUpperCase(),
    },
    {
      link: linkToDashboard,
      text: TR_goToDashboard?.toUpperCase(),
    },
  ];

  const pauseAlert = () => {
    getBackendSrv().post(`/api/alerts/${alertId}/pause`, { paused: alertStatus === 'paused' ? false : true });
    dispatch({ type: 'SET_PAUSE_TRIGGER', payload: pauseTrigger + 1 });
  };

  return (
    <div className={styles.buttonContainer}>
      <div style={{ display: 'flex' }}>
        {buttons.map((button) => (
          <div style={{ marginRight: '5px' }} key={button.link}>
            <Button size={'sm'} onClick={() => window.open(button.link)}>
              {button.text}
            </Button>
          </div>
        ))}
      </div>
      <div>
        <Button
          size={'sm'}
          onClick={() => pauseAlert()}
          variant={'secondary'}
          icon={alertStatus === 'paused' ? 'play' : 'pause'}>
          {alertStatus === 'paused' ? TR_unpauseAlert?.toUpperCase() : TR_pauseAlert?.toUpperCase()}
        </Button>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    buttonContainer: css`
      display: flex;
      padding: 8px;
      width: 100%;
      justify-content: space-between;
      background-color: ${theme.colors.background.secondary};
    `,
  };
};
