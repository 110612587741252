import { useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import * as React from 'react';
import { MachineFilterProps } from 'app-context/types';
import { DARK_BLUE_FILTER, BLUE_MACHINE_FILTER } from 'helpers/colors';
import { DroppableAreaForMacAddresses } from './DroppableAreaForMacAddresses';
import { FilterTitle } from './FilterTitle';

interface FilterDraggableMachineProps {
  changeFilterName: (filter: MachineFilterProps, value: string) => void;
  editFilterName: (filterId: string, isEditing: boolean) => void;
  filter: MachineFilterProps;
  getDeleteButton: (filterId: string) => JSX.Element | undefined;
  getEditButton: (filterId: string) => JSX.Element;
  isEditingFilter: boolean;
  showMacAddresses: boolean;
  showTags: boolean;
}

export const MachineFilter: React.FunctionComponent<FilterDraggableMachineProps> = ({
  changeFilterName,
  editFilterName,
  filter,
  getDeleteButton,
  getEditButton,
  isEditingFilter,
  showMacAddresses,
  showTags,
}) => {
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.machineRow}>
      {/* Display and Edit machine filter name */}
      <div className={styles.titleContainer}>
        <FilterTitle
          changeFilterName={changeFilterName}
          editFilterName={editFilterName}
          filter={filter}
          isEditingFilter={isEditingFilter}
          getEditButton={getEditButton}
          getDeleteButton={getDeleteButton}
        />
      </div>
      {/* Machine's droppable area for mac addresses */}
      <DroppableAreaForMacAddresses
        droppableIdCustom={`dropMachine_${filter.id}`}
        filterId={filter.id}
        showMacAddresses={showMacAddresses}
        showTags={showTags}
      />
    </div>
  );
};

const getStyles = () => {
  return {
    machineRow: css`
      background-color: ${BLUE_MACHINE_FILTER};
      color: ${DARK_BLUE_FILTER};
      font-size: 16px;
      font-weight: 500;
      margin: 5px;
      opacity: 1;
      padding: 6px;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
    `,
    titleContainer: css`
      width: 100%;
      padding: 2px;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
    `,
    transparencyItem: css`
      opacity: 0.5;
    `,
    opacityItem: css`
      opacity: 1;
    `,
  };
};
