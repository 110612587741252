import { getBackendSrv, locationService } from '@grafana/runtime';
import {
  AlertReceivedProps,
  FilterProps,
  AlertsColumnProps,
  StrategyNames,
  AnomalyContentProps,
} from '../app-context/types';
import { NotificationError } from '../shared/NotificationMessage';
import { API_ALERTS, API_TAGS_LIST, API_SEARCH, API_DASHBOARD_BY_UID, API_SAVE_JSON, URL_STRATEGY_TEXTS } from './URLS';
import { PREFIX_MACHINE_FILTER } from 'helpers/constants';
import { SelectableValue } from '@grafana/data';
import { groupAlertsByDashboard } from 'helpers/helpersFilters';
import { getDashboardInfo, getJson } from 'helpers';
import { AnomalyClassText } from 'app-context/translation-types';

/** Get all grafana alerts */
export const getAlerts = async (userRole: string, commonAlerts: any) => {
  try {
    const resultAlert = await getBackendSrv().get(API_ALERTS);
    const goToPanel = userRole !== 'Viewer' ? 'editPanel' : 'viewPanel';
    const alerts = resultAlert?.map((alert: AlertReceivedProps) => {
      /** Consider renamed alert by customers.
       * To get a relevant synthetic alert table, rename all alerts that include :
       *  Battery, Anomaly score and Vibration severity
       */
      let alertName = alert.name.replace('alert', '');
      switch (true) {
        case alert.name?.toUpperCase().includes(commonAlerts.battery?.toUpperCase()):
          alertName = commonAlerts.battery?.toUpperCase();
          break;
        case alert.name?.toUpperCase().includes(commonAlerts.anomalyScore?.toUpperCase()):
          alertName = commonAlerts.anomalyScore?.toUpperCase();
          break;
        case alert.name?.toUpperCase === commonAlerts.vibrationSev?.toUpperCase():
          alertName = commonAlerts.vibrationSev?.toUpperCase();
          break;
        case alert.name?.toUpperCase === commonAlerts.vibrationSevAlarm?.toUpperCase():
          alertName = commonAlerts.vibrationSevAlarm?.toUpperCase();
          break;
        case alert.name?.toUpperCase === commonAlerts.vibrationSevTrip?.toUpperCase():
          alertName = commonAlerts.vibrationSevTrip?.toUpperCase();
          break;
        default:
          break;
      }

      return {
        alertId: alert.id,
        dashboardId: alert.dashboardId,
        dashboardSlug: alert.dashboardSlug,
        dashboardUid: alert.dashboardUid,
        dashboardUrl: alert.url,
        name: alertName,
        newStateDate: alert.newStateDate,
        panelId: alert.panelId,
        status: alert.state,
        url: `${alert.url}?tab=alert&${goToPanel}=${alert.panelId}`,
        value:
          alert.evalData &&
          alert.evalData.evalMatches &&
          alert.evalData.evalMatches.map((evalMatch: any) => ({
            metric: evalMatch.metric,
            value: evalMatch.value,
          })),
      };
    });
    return alerts;
  } catch (err: any) {
    NotificationError(err);
    console.error(err?.message);
    return;
  }
};

/** Get all tags */
export const getTags = async () => {
  try {
    const results = await getBackendSrv().get(API_TAGS_LIST);

    const machineFilters = results
      .filter((result: { term: string }) => result.term?.includes(PREFIX_MACHINE_FILTER))
      .map((result: { term: string; count: number }) => {
        const splitTag = result.term.split('_');
        if (splitTag.length) {
          return { term: splitTag[1], count: result.count };
        }
        return result;
      });

    const otherTags = results.filter(
      (result: { term: string }) =>
        !result.term?.includes(PREFIX_MACHINE_FILTER) &&
        result.term.toLowerCase() !== 'summary' &&
        result.term.toLowerCase() !== 'machine' &&
        result.term.toLowerCase() !== 'main' &&
        result.term.toLowerCase() !== 'comparison'
    );
    const formatedTags = otherTags?.map((tag: { term: string; count: number }) => ({
      label: tag.term,
      value: tag.term,
    }));
    const formatedMachines = machineFilters?.map((tag: { term: string; count: number }) => ({
      label: tag.term,
      value: tag.term,
    }));
    return {
      machineFilters: formatedMachines as FilterProps[],
      otherTags: formatedTags as FilterProps[],
    };
  } catch (err: any) {
    NotificationError(err);
    console.error(err?.message);
    return;
  }
};

export const getAlertColumns = async (groupByDashboard: any[], datasourceId: number, allColumnsSelected: any[]) => {
  let dashboardsAlertsColumns: any[] = [];

  for (const dashboard of groupByDashboard) {
    const dashboardInfo = await getDashboardInfo(dashboard.dashboardUid, datasourceId);

    if (dashboardInfo && !Object.keys(dashboardInfo).length) {
      return;
    }

    const additionnalTags = dashboardInfo?.dashboardTags?.filter(
      (tag) => !tag.includes(PREFIX_MACHINE_FILTER) && tag.toLowerCase() !== 'machine'
    );

    // Inject dashboard info
    dashboard.dashboardTitle = dashboardInfo?.dashboardName;
    dashboard.dashboardTags = additionnalTags;
    dashboard.dashboardMacAddress = dashboardInfo?.dashboardMacAddress;
    dashboard.machineId = dashboardInfo?.machineId;
    dashboard.dashboardUrl = dashboardInfo?.dashboardUrl;
    dashboard.folderTitle = dashboardInfo?.folderTitle;
    dashboard.folderId = dashboardInfo?.folderId;
    dashboard.dashboardModel = dashboardInfo?.dashboardModel;
    dashboard.dashboardImage = dashboardInfo?.dashboardImage;
    dashboard.dashboardId = dashboardInfo?.dashboardId;
    dashboard.lengthUnit = dashboardInfo?.lengthUnit;
    dashboard.processFunction = dashboardInfo?.processFunction;

    for (const dashboardAlert of dashboard.alerts) {
      if (dashboardsAlertsColumns.findIndex((alert) => alert?.Header === dashboardAlert.name?.toUpperCase()) === -1) {
        dashboardsAlertsColumns.push({
          Header: dashboardAlert.name?.toUpperCase(),
          accessor: dashboardAlert.name.replace(/\s/g, '-').toLowerCase(),
          isChecked: true,
        });
      }
    }
  }

  if (allColumnsSelected?.length) {
    const newColumns = allColumnsSelected.filter((colSelected) =>
      dashboardsAlertsColumns.findIndex((col) => col.Header?.toUpperCase() === colSelected.Header?.toUpperCase()) !== -1
        ? true
        : false
    );
    return newColumns as AlertsColumnProps[];
  }
  return dashboardsAlertsColumns as AlertsColumnProps[];
};

export const getUserFolders = async () => {
  const folderOptions: SelectableValue[] = [{ label: 'General', value: 0 }];

  await getBackendSrv()
    .get(`${API_SEARCH}?folderIds=0`) // General folder
    .then(async (result) => {
      if (!result) {
        return;
      }

      for (const folder of result) {
        if (folder.type === 'dash-folder') {
          await getBackendSrv()
            .get(`${API_SEARCH}?folderIds=${folder.id}`)
            .then((resultDashboards) => {
              if (resultDashboards.length) {
                folderOptions.push({ label: folder.title, value: folder.id });
              }
            })
            .catch((error) => {
              console.log(error);
              NotificationError(error);
            });
        }
      }
    })
    .catch((error: any) => {
      console.log(error);
      NotificationError(error);
      return;
    });
  return folderOptions;
};

export const getDashboardsAndAlerts = async (
  orgRole: string,
  datasourceId: number,
  allColumnsSelected: any[],
  commonAlerts: any
) => {
  const alerts = await getAlerts(orgRole, commonAlerts);
  if (!alerts) {
    return;
  }

  const groupByDashboard = groupAlertsByDashboard(alerts);
  if (!groupByDashboard) {
    return;
  }
  const alertsColumns = await getAlertColumns(groupByDashboard, datasourceId, allColumnsSelected);

  return { dashboards: groupByDashboard, alertColumns: alertsColumns };
};

export const getSyntheticDashboardJsonModel = async (uid: string) => {
  const result = await getBackendSrv()
    .get(`${API_DASHBOARD_BY_UID}${uid}`)
    .catch((err) => console.log(err));
  return result?.dashboard;
};

export const saveDashboardJsonModel = async (dashboardJson: any) => {
  await getBackendSrv()
    .post(API_SAVE_JSON, dashboardJson)
    .then(() => locationService.reload())
    .catch((error) => {
      console.error(error);
    });
};

/** Get texts of defaults depending on strategy */
export const getStrategyTexts = async (lang: string, strategy: StrategyNames, processFunction: string) => {
  const strategies = await getJson(`${URL_STRATEGY_TEXTS}${lang}.json`);
  const strategyTexts = strategies[strategy] as AnomalyContentProps[];

  const filteredStrategyTexts = [] as AnomalyClassText[];
  for (const anomaly of strategyTexts) {
    let causes = [] as string[];

    // Function related causes
    anomaly.content.causes.forEach((cause) => {
      if (cause.id === processFunction) {
        causes.push(...cause.texts);
      }
    });

    // General causes
    anomaly.content.causes.forEach((cause) => {
      if (cause.id === 'all') {
        causes.push(...cause.texts);
      }
    });

    const filteredStrategy = { ...anomaly, content: { causes: causes, symptoms: anomaly.content.symptoms } };
    filteredStrategyTexts.push(filteredStrategy);
  }
  return filteredStrategyTexts;
};
