import { GrafanaTheme2 } from '@grafana/data';
import { Button, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import React from 'react';
import {
  AlertDetailsHeader,
  AlertDetailsNavigationButtons,
  AlertFaultCategory,
  AlertStateHistory,
  AlertAnnotation,
} from '.';

interface AlertInfoModalProps {
  openModal: (cell: any, cellIndex: number | null) => void;
  cellInfo: { details: any; cellIndex: number | null };
}

export const AlertDetailsModal: React.FunctionComponent<AlertInfoModalProps> = ({ openModal, cellInfo }) => {
  const { state } = useAppState();
  const { dictionary_uiElements } = state;
  /** UI translation */
  const { TR_anomalyScore, TR_close } = dictionary_uiElements;
  const { details, cellIndex } = cellInfo;

  const alertName = React.useMemo(() => details?.column?.Header?.toUpperCase(), [details]);

  const macAddress = React.useMemo(
    () => (details?.row?.values?.dashboardName?.length ? details?.row?.values?.dashboardName[2] : ''),
    [details]
  );

  const dashboardId = React.useMemo(
    () => (details?.row?.values?.dashboardName?.length ? details?.row?.values?.dashboardName[4] : ''),
    [details]
  );

  // Used to retrieve anomaly texts, dependong on process_function
  const dashboardFunction = React.useMemo(
    () => (details?.row?.values?.dashboardName?.length ? details?.row?.values?.dashboardName[5] : ''),
    [details]
  );

  const status = React.useMemo(() => (details?.value?.length ? details?.value[1] : ''), [details]);
  const panelId = React.useMemo(() => (details?.value?.length ? details?.value[4] : ''), [details]);

  const IS_ANOMALY_SCORE_ALERTING = React.useMemo(
    () => status === 'alerting' && alertName?.toUpperCase() === TR_anomalyScore?.toUpperCase(),
    [status] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const styles = useStyles2(getStyles);

  return (
    <div className={styles.modal}>
      <div className={styles.modalContent}>
        <div className={styles.infoContainer}>
          <AlertDetailsHeader cellInfo={cellInfo.details} />
          <AlertDetailsNavigationButtons cellInfo={cellInfo.details} />
          <div className={styles.alertExtraInfo}>
            {/* If alerting, display the results of incident advisor */}
            {IS_ANOMALY_SCORE_ALERTING && (
              <AlertFaultCategory macAddress={macAddress} processFunction={dashboardFunction} />
            )}
            {/* State history list */}
            <AlertStateHistory dashboardId={dashboardId} panelId={panelId} />
            {/* Annotation list */}
            <AlertAnnotation dashboardId={dashboardId} panelId={panelId} />
          </div>
        </div>
        <div>
          <Button onClick={() => openModal(details, cellIndex)} icon={'times'} size={'md'}>
            {TR_close}
          </Button>
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modal: css`
      position: fixed;
      z-index: 1001;
      padding-top: 100px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: #00000094;
    `,
    modalContent: css`
      background-color: ${theme.colors.background.primary};
      margin: auto;
      padding: 20px;
      border: 1px solid ${theme.colors.border.weak};
      width: 65%;
      height: 80%;
      @media (max-width: 768px) {
        width: 95%;
        height: 80%;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    `,
    stateHistoryContainer: css`
      padding: 12px;
    `,
    infoContainer: css`
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
      overflow: auto;
      margin-bottom: 8px;
    `,
    alertExtraInfo: css`
      display: flex;
      flex-direction: column;
      max-height: 420px;
      overflow: auto;
      padding: 12px;
    `,
  };
};
