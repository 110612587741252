import { Icon, Input, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import React, { useState } from 'react';
import { MachineFilterProps } from '../../app-context/types';

interface FilterEditionProps {
  defaultValue: string;
  editFilterName: (machineId: string, isEditing: boolean) => void;
  filter: MachineFilterProps;
  changeFilterName: (machine: MachineFilterProps, value: string) => void;
}

export const FilterEdition: React.FunctionComponent<FilterEditionProps> = ({
  defaultValue,
  editFilterName,
  filter,
  changeFilterName,
}) => {
  const [name, setName] = useState(defaultValue);
  const styles = useStyles2(getStyles);

  const handleName = (e: any) => {
    e.preventDefault();
    setName(e.target.value);
  };

  return (
    <div className={styles.mainContainer}>
      <Input
        value={name}
        onChange={(e) => handleName(e)}
        onKeyPress={(e) => e.key === 'Enter' && changeFilterName(filter, name)}
        className={css`
          &:focus {
            border: 1px solid blue;
          }
        `}
      />
      <div className={styles.iconContainer}>
        <Icon name={'check'} onClick={() => changeFilterName(filter, name)} size={'lg'} />
      </div>
      <div className={styles.iconContainer}>
        <Icon name={'times'} onClick={() => editFilterName(filter.id, false)} size={'lg'} />
      </div>
    </div>
  );
};

const getStyles = () => {
  return {
    mainContainer: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `,
    iconContainer: css`
      cursor: pointer;
      padding: 2px;
    `,
  };
};
