import React, { useEffect, useState } from 'react';
import { useStyles2, Drawer, IconName, Icon } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
//@ts-ignore
import { PieChart } from 'react-minimal-pie-chart';
import { useAppState } from '../app-context/AppStateContext';
import { css } from '@emotion/css';
import { DANGER_ASYSTOM, GREEN_ASYSTOM, WARNING_ASYSTOM } from '../helpers/colors';
import { getStateDisplayModel } from 'helpers/helpersFilters';

interface CountDashboardsPieProps {}

interface PieSegmentProps {
  title: string;
  value: number;
  color: string;
  status: string;
}

interface DashboardList {
  title: string;
  macAddress: string;
  url: string;
  dashboardMachine: string;
}

interface ListsProps {
  ok: DashboardList[];
  noData: DashboardList[];
  paused: DashboardList[];
  incident: DashboardList[];
}

export const CountDashboardsPie: React.FunctionComponent<CountDashboardsPieProps> = () => {
  const { state } = useAppState();
  const { selectedDashboards, dictionary_uiElements } = state;
  const {
    TR_battery,
    TR_beacon,
    TR_beacons,
    TR_dashboardsOkText,
    TR_dashboardsNoDataText,
    TR_dashboardsIncidentText,
    TR_dashboardsPausedText,
    TR_withIssues,
  } = dictionary_uiElements;

  const [dataPie, setDataPie] = useState<PieSegmentProps[]>([]);
  const [dashboardsLists, setDashboardsLists] = useState<ListsProps>();
  const [isListOpen, setIsListOpen] = useState(false);
  const [listToDisplay, setListToDisplay] = useState<DashboardList[]>([]);
  const [hovered, setHovered] = useState<number | undefined>(undefined);
  const [currentListStatus, setCurrentListStatus] = useState('');

  const styles = useStyles2(getStyles);

  const onSegmentClick = (indexSegment: number) => {
    if (!dashboardsLists) {
      return;
    }
    setIsListOpen(true);
    const selectedStatus = dataPie[indexSegment].status;

    switch (selectedStatus) {
      case 'ok':
        setListToDisplay(dashboardsLists?.ok);
        setCurrentListStatus(selectedStatus);
        break;
      case 'paused':
        setListToDisplay(dashboardsLists?.paused);
        setCurrentListStatus(selectedStatus);
        break;
      case 'no data':
        setListToDisplay(dashboardsLists?.noData);
        setCurrentListStatus(selectedStatus);
        break;
      case TR_withIssues:
        setListToDisplay(dashboardsLists?.incident);
        setCurrentListStatus(selectedStatus);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (selectedDashboards?.length) {
      if (selectedDashboards.length) {
        let dashboardsOk = 0;
        let dashboardsNoData = 0;
        let dashboardsIncident = 0;
        let dashboardsPaused = 0;

        let dashboardsOkList: DashboardList[] = [];
        let dashboardsPausedList: DashboardList[] = [];
        let dashboardsIncidentList: DashboardList[] = [];
        let dashboardsNodataList: DashboardList[] = [];

        for (const dashboard of selectedDashboards) {
          if (dashboard.alerts.length) {
            const batteryIndex = dashboard.alerts?.findIndex(
              (alert) => alert.name?.toUpperCase() === TR_battery?.toUpperCase()
            );
            if (batteryIndex !== -1) {
              if (dashboard.alerts[batteryIndex]?.status === 'no_data') {
                const dashboardItemList = {
                  title: dashboard.dashboardTitle,
                  macAddress: dashboard.dashboardMacAddress,
                  url: dashboard.dashboardUrl,
                  dashboardMachine: dashboard.machineId,
                };
                dashboardsNodataList.push(dashboardItemList);
                dashboardsNoData += 1;
                continue;
              }
            }

            let countOk = 0;
            let countAlerting = 0;
            let countNoData = 0;
            let countPaused = 0;

            for (const alert of dashboard.alerts) {
              if (!alert) {
                continue;
              }
              switch (alert?.status) {
                case 'ok':
                  countOk += 1;
                  break;
                case 'alerting':
                  countAlerting += 1;
                  break;
                // Also count alert with no data (when battery is ok). Counted in dashboards with incidents
                case 'no_data':
                  countNoData += 1;
                  break;
                case 'paused':
                  countPaused += 1;
                  break;
                default:
                  break;
              }
            }
            const dashboardItemList = {
              title: dashboard.dashboardTitle,
              macAddress: dashboard.dashboardMacAddress,
              url: dashboard.dashboardUrl,
              dashboardMachine: dashboard.machineId,
            };
            if (countPaused === dashboard.alerts.length) {
              dashboardsPausedList.push(dashboardItemList);
              dashboardsPaused += 1;
              continue;
            }

            if (countOk === dashboard.alerts.length || countPaused + countOk === dashboard.alerts.length) {
              dashboardsOkList.push(dashboardItemList);
              dashboardsOk += 1;
              continue;
            }

            if (countNoData === dashboard.alerts.length || countPaused + countNoData === dashboard.alerts.length) {
              dashboardsNodataList.push(dashboardItemList);
              dashboardsNoData += 1;
              continue;
            }

            if (countAlerting > 0) {
              dashboardsIncidentList.push(dashboardItemList);
              dashboardsIncident += 1;
              continue;
            }
            // considering dashboards with battery ok and that don't match previous conditions, as OK
            // TO DO - indicate to user if a learning has been made or not
            if (batteryIndex !== -1 && dashboard.alerts[batteryIndex]?.status === 'ok') {
              dashboardsOkList.push(dashboardItemList);
              dashboardsOk += 1;
            }
          }
        }

        const newDataPie = [
          { title: TR_dashboardsIncidentText, value: dashboardsIncident, color: DANGER_ASYSTOM, status: TR_withIssues },
          { title: TR_dashboardsNoDataText, value: dashboardsNoData, color: WARNING_ASYSTOM, status: 'no data' },
          { title: TR_dashboardsOkText, value: dashboardsOk, color: GREEN_ASYSTOM, status: 'ok' },
          { title: TR_dashboardsPausedText, value: dashboardsPaused, color: '#afafaf', status: 'paused' },
        ];

        const lists = {
          ok: dashboardsOkList,
          noData: dashboardsNodataList,
          paused: dashboardsPausedList,
          incident: dashboardsIncidentList,
        };
        setDashboardsLists(lists);
        setDataPie(newDataPie?.filter((data) => data?.value));
      }
    } else {
      setDataPie([]);
    }
  }, [selectedDashboards]); // eslint-disable-line react-hooks/exhaustive-deps

  const totalDashboards = dataPie?.reduce((acc, current) => (acc += current.value), 0);
  const dataWithHovered = dataPie.map((entry, i) => {
    if (hovered === i) {
      return {
        ...entry,
        color: `${entry.color}B3`, // 70% opacity
      };
    }
    return entry;
  });

  return (
    <div className={styles.pieContainer}>
      <div
        className={css`
          max-width: 175px;
        `}>
        {totalDashboards !== 0 ? (
          <PieChart
            data={dataWithHovered}
            lineWidth={32}
            paddingAngle={2}
            viewBoxSize={[100, 100]}
            segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
            animate
            labelStyle={(index: number) => ({
              fill: dataPie[index].color,
              fontSize: '4.5px',
              fontFamily: 'sans-serif',
              fontWeight: 500,
            })}
            radius={42}
            onClick={(segment: any, index: number) => onSegmentClick(index)}
            onMouseOver={(segment: any, index: number) => {
              setHovered(index);
            }}
            onMouseOut={() => {
              setHovered(undefined);
            }}
          />
        ) : (
          <PieChart
            data={[{ title: '', value: 50, color: '#e6e6e6' }]}
            lineWidth={32}
            viewBoxSize={[100, 100]}
            paddingAngle={2}
            radius={42}
          />
        )}
      </div>
      {dataPie.length !== 0 ? (
        totalDashboards !== 0 && (
          <div className={styles.countContainer}>
            <div style={{ fontSize: '30px', fontWeight: 600 }}>
              {totalDashboards} {totalDashboards > 1 ? TR_beacons?.toUpperCase() : TR_beacon?.toUpperCase()}
            </div>
            {dataPie?.map((data, index) => (
              <div
                className={styles.totalItem}
                style={{ color: `${data.color}` }}
                onClick={() => onSegmentClick(index)}
                key={`${data.color}-${index}`}>
                <div>
                  {data.value} {data.status?.toUpperCase()}
                </div>
                <span>
                  <Icon name={'eye'} size={'lg'} />
                </span>
              </div>
            ))}
          </div>
        )
      ) : (
        <div style={{ fontSize: '30px', fontWeight: 600 }}>0 {TR_beacon?.toUpperCase()}</div>
      )}
      {/* Open a list of dashboards on pie click */}
      {isListOpen && (
        <ListDrawer setIsListOpen={setIsListOpen} listToDisplay={listToDisplay} currentListStatus={currentListStatus} />
      )}
    </div>
  );
};

interface ListDrawerProps {
  setIsListOpen: (value: React.SetStateAction<boolean>) => void;
  listToDisplay: DashboardList[];
  currentListStatus: string;
}

const ListDrawer: React.FunctionComponent<ListDrawerProps> = ({ setIsListOpen, listToDisplay, currentListStatus }) => {
  const { state } = useAppState();
  const { dictionary_uiElements, theme } = state;
  const { TR_withIssues, TR_dashboards } = dictionary_uiElements;

  const getStatus = (currentListStatus: string) => {
    let status = currentListStatus;

    switch (currentListStatus) {
      case TR_withIssues:
        status = 'alerting';
        break;
      case 'no data':
        status = 'no_data';
        break;
      default:
        break;
    }
    return status;
  };

  const getDrawerTitle = (status: string) => {
    let title = status;
    switch (status) {
      case TR_withIssues:
        status = 'alerting';
        break;
      case 'no data':
        status = 'no_data';
        break;
      default:
        break;
    }
    return `${TR_dashboards} ${title}`.toUpperCase();
  };

  return (
    <Drawer title={getDrawerTitle(currentListStatus)} onClose={() => setIsListOpen(false)}>
      <div
        className={css`
          display: flex;
          flex-direction: column;
          overflow: auto;
          max-height: 85vh;
        `}>
        <ol className="alert-rule-list">
          {listToDisplay?.map((item) => (
            <li
              className="alert-rule-item"
              style={{ cursor: 'pointer', height: '68px' }}
              key={item.url}
              onClick={() => window.open(item.url)}>
              <div
                className={`alert-rule-item__icon ${getStateDisplayModel(getStatus(currentListStatus)).stateClass}`}
                style={{ color: `${getStateDisplayModel(getStatus(currentListStatus)).bgColor}` }}>
                <Icon
                  name={getStateDisplayModel(getStatus(currentListStatus)).iconClass as IconName}
                  size={'xl'}
                  style={{ marginRight: '4px' }}></Icon>
              </div>
              <div className="alert-rule-item__body">
                <div className="alert-rule-item__header">
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="alert-rule-item__name">{item.title}</div>
                    {item.dashboardMachine && (
                      <div
                        style={{
                          border: `1px solid ${getStateDisplayModel(getStatus(currentListStatus)).bgColor}`,
                          padding: '2px 9px',
                          fontSize: '11px',
                          fontWeight: 500,
                          borderRadius: '3px',
                          color: theme.isDark ? '#000000' : '',
                        }}>
                        <Icon name="cog" /> {item.dashboardMachine}
                      </div>
                    )}
                  </div>
                  <span>{item.macAddress}</span>
                  <div style={{ marginTop: '4px' }}></div>
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </Drawer>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    pieContainer: css`
      height: 180px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 12px 4px 0 32px;
    `,
    countContainer: css`
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 6px 12px;
    `,
    totalItem: css`
      font-size: 18px;
      font-weight: 600;
      display: flex;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;
      > span {
        display: none;
      }
      &:hover {
        > span {
          display: block;
        }
      }
    `,
  };
};
