import React from 'react';
import { Icon, useStyles2, IconName } from '@grafana/ui';
import { css } from '@emotion/css';
import { DANGER_ASYSTOM, GREEN_ASYSTOM } from 'helpers/colors';

interface CountAlertsProps {
  countAlerts: {
    alerting: number;
    noData: number;
    ok: number;
    paused: number;
    unknown: number;
  };
  selectedStatus: {
    ok: boolean;
    noData: boolean;
    alerting: boolean;
    paused: boolean;
  };
}

export const CountAlerts: React.FunctionComponent<CountAlertsProps> = ({ countAlerts, selectedStatus }) => {
  const styles = useStyles2(getStyles);

  const countStatuses = [
    {
      status: selectedStatus.alerting,
      color: DANGER_ASYSTOM,
      count: countAlerts.alerting,
      icon: 'exclamation-triangle' as IconName,
    },
    {
      status: selectedStatus.noData,
      color: '#e2ad1a',
      count: countAlerts.noData,
      icon: 'question-circle' as IconName,
    },
    {
      status: selectedStatus.ok,
      color: GREEN_ASYSTOM,
      count: countAlerts.ok,
      icon: 'check-circle' as IconName,
    },
    {
      status: selectedStatus.paused,
      color: 'gray',
      count: countAlerts.paused,
      icon: 'pause' as IconName,
    },
  ];

  return (
    <div
      className={css`
        display: flex;
        align-items: center;
      `}>
      {countStatuses.map(
        (countStatus, index) =>
          countStatus?.status && (
            <div
              key={`${countStatus.icon}-${index}`}
              className={styles.alert}
              style={{ color: countStatus.color, opacity: countStatus.count === 0 ? 0.3 : 1, fontSize: '24px' }}>
              <Icon name={countStatus.icon} size={'xl'} style={{ marginRight: '3px' }} />
              <div>{countStatus.count}</div>
            </div>
          )
      )}
    </div>
  );
};

const getStyles = () => {
  return {
    alert: css`
      padding: 3px;
      margin-right: 6px;
      font-weight: 500;
      display: flex;
      align-items: center;
    `,
  };
};
