import * as React from 'react';
import { css } from '@emotion/css';
import { Spinner } from '@grafana/ui';

interface FilterLoadingProps {
  text: string;
}

export const FilterLoading: React.FunctionComponent<FilterLoadingProps> = ({ text }) => {
  return (
    <div
      className={css`
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
      `}>
      {text}... <Spinner />
    </div>
  );
};
