import * as React from 'react';
import { getTemplateSrv, config } from '@grafana/runtime';
import { useTheme2 } from '@grafana/ui';
import { AppState, TemplatesVar, DashboardWithAlerts, FilterProps, MacAddressProps } from './types';
import { DataSourceApi } from '@grafana/data';
import { TranslationProps } from './translation-types';

const getTemplateCurrentText = (text: string) => {
  const getTemplate = getTemplateSrv().getVariables() as any;
  const template = getTemplate && getTemplate.filter((template: { name: string; }) => template.name === text);
  if (!template.length) {
    // NotificationError({ message: `Template ${text} not found` });
    return;
  }
  return template[0].current?.value;
};

/**
 * Initialize default state and pass it to the main app component
 */

export const withData = (
  WrappedComponent: React.ComponentType<React.PropsWithChildren<{ initialState: AppState; }>>
) => {
  // eslint-disable-next-line react/display-name
  return ({ children }: React.PropsWithChildren<{}>) => {
    const userPath = config.bootData.user;
    const langTemplate = getTemplateCurrentText(TemplatesVar.lang);

    const currentTheme = useTheme2();
    let langSelect = '';

    if (!langTemplate) {
      langSelect = 'EN';
    } else {
      langSelect = langTemplate as string;
    }

    const defaultState = {
      alertsColumns: [],
      dashboardsWithAlerts: [] as DashboardWithAlerts[],
      datasourceInflux: {} as DataSourceApi,
      datasourceMysql: {} as DataSourceApi,
      datasourceJson: {} as DataSourceApi,
      dictionary_uiElements: {} as TranslationProps,
      lang: langSelect,
      machineFilters: [],
      selectedMachines: [] as FilterProps[],
      selectedTags: [],
      selectedDashboards: [] as DashboardWithAlerts[],
      selectedFolders: [] as FilterProps[],
      selectedStatus: { alerting: true, noData: true, ok: false, paused: false },
      tags: [],
      theme: currentTheme,
      user: {
        isGrafanaAdmin: userPath.isGrafanaAdmin,
        orgName: userPath.orgName,
        orgId: userPath.orgId,
        orgRole: userPath.orgRole,
        timeZone: userPath.timezone,
        name: userPath.name,
      },
      // filters management
      macAddresses: [] as MacAddressProps[],
      selectedFolder: { label: 'General', value: 0 },
      pauseTrigger: 0,
    };

    const [initialState] = React.useState<AppState>(defaultState);
    //@ts-ignore
    return <WrappedComponent initialState={initialState}>{children}</WrappedComponent>;
  };
};
