import * as React from 'react';
import { Icon } from '@grafana/ui';

interface ArrowIconsProps {
  isOpen: boolean;
}

export const ArrowIcons: React.FunctionComponent<ArrowIconsProps> = ({ isOpen }) => {
  return (
    <div style={{ marginRight: '10px' }}>{isOpen ? <Icon name={'angle-down'} /> : <Icon name={'angle-right'} />}</div>
  );
};
