import React from 'react';
import { css } from '@emotion/css';
import { Icon, useStyles2, IconName } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { GrafanaTheme2 } from '@grafana/data';

interface SyntheticTitleProps {}

export const MainTitle: React.FunctionComponent<SyntheticTitleProps> = () => {
  const { state } = useAppState();
  const { dictionary_uiElements, theme, user } = state;
  const { TR_syntheticDashboard, TR_realtimeAlertState } = dictionary_uiElements;
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.headerContainer}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Icon name={'bell'} size={'xxxl'} style={{ marginRight: '15px', color: theme.colors.text.secondary }} />
        <div>
          <div className={styles.mainTitle}>{TR_syntheticDashboard}</div>
          <div className={styles.subtitle}>{TR_realtimeAlertState}</div>
        </div>
      </div>
      <div className={styles.userSection}>
        <div className={styles.iconstyle}>
          <Icon name={'building' as IconName} /> {user.orgName}
        </div>
        <div style={{ padding: '4px' }}>
          <Icon name={'user'} /> {user.name}
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    headerContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      background-color: ${theme.isDark ? '#1f1f20' : '#fbfbfb'};
    `,
    mainTitle: css`
      font-size: 26px;
      font-weight: 500;
      color: ${theme.colors.text.secondary};
    `,
    subtitle: css`
      color: ${theme.colors.text.secondary};
    `,
    userSection: css`
      color: ${theme.colors.text.secondary};
      font-weight: 500;
      font-size: 16px;
      margin-right: 12px;
      display: flex;
    `,
    iconstyle: css`
      padding: 4px;
      margin-right: 5px;
    `,
  };
};
