import * as React from 'react';
import { PanelProps } from '@grafana/data';
import { AppStateProvider } from './app-context/AppStateContext';
import { MainContainer } from './components/MainContainer';

export const SimplePanel: React.FunctionComponent<PanelProps> = ({ data }) => {
  return (
    <AppStateProvider>
      <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <MainContainer data={data} />
      </div>
    </AppStateProvider>
  );
};
