import React, { useState } from 'react';
import { Icon, useStyles2 } from '@grafana/ui';
import { css } from '@emotion/css';
import { MachineFilterProps } from 'app-context/types';
import { useAppState } from 'app-context/AppStateContext';
import { checkIfNameExistsInMachineGroup } from 'components/filters/helpersFilters';
import { NotificationError } from 'shared/NotificationMessage';
import { GrafanaTheme2 } from '@grafana/data';

interface AddMachineFormProps {
  folderId: number;
  machineFilters: MachineFilterProps[];
  setIsAddingMachineFilter: (isAdding: boolean) => void;
  setMachineFilters: (value: React.SetStateAction<MachineFilterProps[]>) => void;
}

export const AddMachineForm: React.FunctionComponent<AddMachineFormProps> = ({
  folderId,
  machineFilters,
  setIsAddingMachineFilter,
  setMachineFilters,
}) => {
  const [value, setValue] = useState('');
  const { state } = useAppState();
  const { dictionary_uiElements } = state;
  const { TR_placeholderCreateMachineFilter, TR_filterAlreadyExists } = dictionary_uiElements;
  const styles = useStyles2(getStyles);

  const addFilter = () => {
    if (!value) {
      setIsAddingMachineFilter(false);
      return;
    }
    const newMachines = [...machineFilters];
    const isNameInMachinesGroup = checkIfNameExistsInMachineGroup(newMachines, folderId, value);
    if (isNameInMachinesGroup) {
      NotificationError({ message: TR_filterAlreadyExists });
      return machineFilters;
    }
    newMachines.push({
      name: value,
      folderId: folderId,
      isEditing: false,
      id: `${value}-${folderId}`,
      isFilterNameModified: false,
    });
    setMachineFilters(newMachines);
    setIsAddingMachineFilter(false);
    return newMachines;
  };

  return (
    <div className={styles.inputContainer}>
      <input
        placeholder={TR_placeholderCreateMachineFilter}
        value={value}
        onChange={(e: any) => setValue(e.target.value)}
        onKeyPress={(e) => e.key === 'Enter' && addFilter()}
        maxLength={30}
        className={styles.inputMachine}
      />
      <div className={styles.buttonValidation}>
        <Icon name={'check'} onClick={addFilter} size={'xl'} />
      </div>
      <div className={styles.buttonValidation}>
        <Icon name={'times'} onClick={() => setIsAddingMachineFilter(false)} size={'xl'} />
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    inputContainer: css`
      display: flex;
      align-items: center;
      width: 100%;
    `,
    inputMachine: css`
      font-size: 16px;
      padding: 2px 8px;
      width: 100%;
      border-radius: 2px;
      &:focus {
        outline-offset: 2px;
        box-shadow: white 0px 0px 0px 2px, #5794f2 0px 0px 0px 4px;
      }
      border: 1px solid ${theme.colors.border.weak};
    `,
    buttonValidation: css`
      cursor: pointer;
      margin: 5px;
      background-color: none;
      &:hover {
        background-color: #ffffff4d;
      }
    `,
  };
};
