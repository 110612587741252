import { TranslationProps } from 'app-context/translation-types';
import { DashboardWithAlerts } from 'app-context/types';
import { formatDistanceToNow } from 'date-fns';
import { enGB, fr } from 'date-fns/locale';
import {
  DANGER_ASYSTOM,
  DANGER_ASYSTOM_LIGHT,
  GREEN_ASYSTOM,
  GREEN_ASYSTOM_LIGHT,
  WARNING_ASYSTOM,
  WARNING_ASYSTOM_LIGHT,
} from 'helpers/colors';

export const buildCustomCells = (cellInfo: any, dico: TranslationProps) => {
  let bgColor = '';
  let textColor = '';
  let textAlignment = 'center';
  let widthCell = '5%';

  let cellValue = cellInfo && cellInfo.value && cellInfo.value[0];
  let cellStatus = cellInfo && cellInfo.value && cellInfo.value[1];

  const noLinkToAlert =
    !cellValue ||
    (cellInfo && cellInfo.value && !cellInfo.value[2]) ||
    cellInfo.column.Header === dico.TR_dashboard?.toUpperCase();
  let cellMinWidth = '80px';

  switch (cellStatus) {
    case 'no_data':
      bgColor = WARNING_ASYSTOM;
      textColor = WARNING_ASYSTOM_LIGHT;
      break;
    case 'ok':
      bgColor = GREEN_ASYSTOM;
      textColor = GREEN_ASYSTOM_LIGHT;
      break;
    case 'alerting':
      bgColor = DANGER_ASYSTOM;
      textColor = DANGER_ASYSTOM_LIGHT;
      break;
    case 'pending':
      bgColor = '#8e8e8e';
      textColor = '#d4d6dd';
      break;
    case 'paused':
      bgColor = '#8e8e8e';
      textColor = '#d4d6dd';
      break;
    default:
      break;
  }

  switch (cellInfo.column.Header) {
    case dico.TR_dashboard?.toUpperCase():
      widthCell = '36%';
      cellMinWidth = '330px';
      break;

    default:
      break;
  }

  return {
    style: {
      backgroundColor: `${bgColor}`,
      color: `${textColor}`,
      fontWeight: 500,
      textAlign: `${textAlignment}`,
      minWidth: cellMinWidth,
      width: `${widthCell}`,
      cursor: `${noLinkToAlert ? 'default' : 'pointer'}`,
    },
  };
};

/** Return dashboard [title, url, mac address, tags,dashboard id],
 * +its alerts [value, status, url, alert date, alert panelId, alert id] */
export const dataToTableDisplay = (dashboardsWithAlerts: DashboardWithAlerts[], lang: string) => {
  const localTime = lang === 'EN' ? { locale: enGB } : { locale: fr };

  const output = dashboardsWithAlerts.sort((e1, e2) =>
    e1.dashboardTitle.toLowerCase() > e2.dashboardTitle.toLowerCase() ? 1 : -1
  );

  const formatDashboardData = output.map((dashboard) => {
    const objKnownAlerts = {
      dashboardName: [
        dashboard.dashboardTitle,
        dashboard.dashboardUrl,
        dashboard.dashboardMacAddress,
        dashboard.dashboardTags,
        dashboard.dashboardId,
        dashboard.processFunction,
      ],
    } as any;

    for (const alert of dashboard.alerts) {
      const formatAlertDate = new Date(alert?.newStateDate);
      const alertDate = formatDistanceToNow(formatAlertDate, localTime);
      let alertValue = '';

      switch (alert?.status) {
        case 'no_data':
          alertValue = `${alert?.status}`;
          break;

        case 'alerting':
          alertValue = JSON.stringify(alert.value);
          break;

        case 'pending':
          alertValue = JSON.stringify(alert.value);
          break;

        case 'ok':
          alertValue = `${alert.alertValue}`;
          break;

        default:
          alertValue = `${alert?.status}`;
          break;
      }
      const name = alert?.name.replace(/\s/g, '-')?.toLowerCase();
      objKnownAlerts[`${name}`] = [alertValue, alert?.status, alert.url, alertDate, alert.panelId, alert.alertId];
    }
    return objKnownAlerts;
  });
  return formatDashboardData;
};
