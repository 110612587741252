import React, { useEffect, useState } from 'react';
import { GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Icon, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { StrategyNames } from 'app-context/types';
import { css } from '@emotion/css';
import { ArrowIcons } from 'shared/ArrowIcons';
import { getDatasourceQueryUrl } from 'helpers';
import { getStrategyTexts } from 'helpers/requests';

interface GetFaultCategoryProps {
  macAddress: string;
  processFunction: string;
}

interface AnomalyValueProps {
  queryName: string;
  value: number;
  name: string;
  content: {
    causes: string[];
    symptoms: string[];
  };
}

export const AlertFaultCategory: React.FunctionComponent<GetFaultCategoryProps> = ({ macAddress, processFunction }) => {
  const { state } = useAppState();
  const { datasourceInflux, lang } = state;
  const [advAnomaliesTexts, setAdvAnomaliesTexts] = useState<AnomalyValueProps[]>([]);
  const styles = useStyles2(getStyles);

  const getIncidentAdvisor = async () => {
    /** 1. Get strategy and associated texts */
    const queryStrategy = `SELECT last("score_anomaly"),"strategy" FROM "Signature_Anomaly" WHERE "device"='${macAddress}'`;
    const strategyResults = await getBackendSrv().get(getDatasourceQueryUrl(queryStrategy, datasourceInflux));

    if (!strategyResults.results.length || !strategyResults?.results[0]?.series) {
      return;
    }

    // columns: (3) ['time', 'last', 'strategy']
    const strategy = strategyResults && strategyResults?.results[0]?.series[0]?.values[0][2];
    const strategyKey = ('strategy-' + strategy) as StrategyNames;

    const strategyTexts = await getStrategyTexts(lang, strategyKey, processFunction);
    const anomalyValues: AnomalyValueProps[] = [];

    let index = 0;

    /** 2. Query all adv related to strategy */
    for (const anomaly of strategyTexts) {
      const queryAdv = `SELECT last("adv_${index}_similarity") * 100 AS "${anomaly.queryName}"  FROM "Signature_Anomaly" WHERE "device"='${macAddress}' AND ("aux_f0" != 0)`;
      const advResults = await getBackendSrv().get(getDatasourceQueryUrl(queryAdv, datasourceInflux));

      if (!advResults.results[0]?.series[0]?.values.length) {
        return;
      }

      const resultValue = advResults.results[0]?.series[0]?.values[0][1];
      let advValue = resultValue;

      if (resultValue < 0) {
        advValue = 0;
      }

      anomalyValues.push({
        queryName: anomaly.queryName,
        value: Math.round(advValue),
        name: anomaly.name,
        content: anomaly.content,
      });
      index += 1;
    }
    /** 3. Only keep the first two highest similarities */
    const mainAnomalies = anomalyValues
      .filter((anomaly) => anomaly.value)
      .sort((a, b) => (a.value < b.value ? 1 : -1))
      .filter((anomaly, index) => index < 2);

    setAdvAnomaliesTexts(mainAnomalies);
  };

  useEffect(() => {
    getIncidentAdvisor();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.incidentAdvisorContainer}>
      {advAnomaliesTexts.length !== 0 && (
        <div className={styles.incidentTitle}>
          <Icon name="bolt" size="lg" /> INCIDENT ADVISOR
        </div>
      )}
      {advAnomaliesTexts.length !== 0 &&
        advAnomaliesTexts.map((anomaly) => <AnomalyContent anomaly={anomaly} key={anomaly.name} />)}
    </div>
  );
};

interface AnomalyContentProps {
  anomaly: AnomalyValueProps;
}

const AnomalyContent: React.FunctionComponent<AnomalyContentProps> = ({ anomaly }) => {
  const { state } = useAppState();
  const { dictionary_uiElements } = state;
  /** UI translation */
  const { TR_similarity, TR_symptoms, TR_rootCauses } = dictionary_uiElements;

  const [isAnomalyOpen, setIsAnomalyOpen] = useState(false);
  const styles = useStyles2(getStyles);

  return (
    <div className={styles.frameContainer}>
      <div className={styles.topSectionContainer} onClick={() => setIsAnomalyOpen(!isAnomalyOpen)}>
        <div className={styles.columnFlex}>
          <div className={styles.anomalyTitle}>
            <ArrowIcons isOpen={isAnomalyOpen} />
            <div>{anomaly.name}</div>
          </div>
        </div>
        <div className={styles.anomalyTitle}>
          {TR_similarity?.toUpperCase()} {anomaly.value} %
        </div>
      </div>
      {isAnomalyOpen && (
        <div className={styles.symptomsCausesContainer}>
          <div className={styles.symptomsContainer}>
            <div className={styles.subTitle}>{TR_symptoms?.toUpperCase()}</div>
            <div className={styles.content}>
              {anomaly.content.symptoms.map((symptom) => (
                <div key={symptom}>- {symptom}</div>
              ))}
            </div>
          </div>
          <div className={styles.causesContainer}>
            <div className={styles.subTitle}>{TR_rootCauses?.toUpperCase()}</div>
            <div className={styles.content}>
              {anomaly.content.causes.map((cause) => (
                <div key={cause}>- {cause}</div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    frameContainer: css`
      padding: 12px;
      border-radius: 4px;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff2f2;
      margin-bottom: 8px;
    `,
    columnFlex: css`
      display: flex;
      flex-direction: column;
    `,
    anomalyTitle: css`
      font-weight: 500;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      color: #2f2f32;
    `,
    content: css`
      padding: 8px;
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 100%;
      color: #424345;
    `,
    topSectionContainer: css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    `,
    subTitle: css`
      padding: 0 8px;
      font-weight: 500;
      color: #343436;
    `,
    symptomsContainer: css`
      display: flex;
      flex-direction: column;
      flex: 1;
    `,
    causesContainer: css`
      display: flex;
      flex-direction: column;
      flex: 1;
      border-left: 2px solid #e3e3e3;
    `,
    symptomsCausesContainer: css`
      display: flex;
      flex-direction: row;
      width: 100%;
      padding: 16px;
    `,
    incidentAdvisorContainer: css`
      display: flex;
      flex-direction: column;
      margin: 5px 0 15px 0px;
      border-radius: 8px;
    `,
    incidentTitle: css`
      color: #f00;
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 4px;
    `,
  };
};
