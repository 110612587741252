import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Button, FilterPill, Icon, IconName, MultiSelect, useStyles2, Tooltip } from '@grafana/ui';
import { css } from '@emotion/css';
import { getSelectedFiltersInfo } from 'helpers/helpersFilters';
import React, { useEffect, useState } from 'react';
import { AlertsColumns, AlertSelectStatus, ModalSaveParameters } from '..';
import { useAppState } from 'app-context/AppStateContext';
import { FilterProps } from 'app-context/types';
import { ArrowIcons } from 'shared/ArrowIcons';

interface FilterSectionProps {
  filterDashboardsByFolder: (options: SelectableValue[]) => void;
  foldersList: SelectableValue[];
  machineFiltersOptions: FilterProps[] | undefined;
}

export const FilterSection: React.FunctionComponent<FilterSectionProps> = ({
  filterDashboardsByFolder,
  foldersList,
  machineFiltersOptions,
}) => {
  const { state, dispatch } = useAppState();
  const {
    dictionary_uiElements,
    selectedDashboards,
    selectedFolders,
    selectedMachines,
    selectedStatus,
    selectedTags,
    tags,
    theme,
    user,
  } = state;
  /** UI translation */
  const {
    TR_alertState,
    TR_filters,
    TR_hideColumns,
    TR_noOption,
    TR_saveFilters,
    TR_selectFolderPlaceholder,
    TR_selectFolders,
    TR_selectMachinePlaceholder,
    TR_selectMachines,
    TR_selectTagPlaceholder,
    TR_selectTags,
    TR_removeFilter,
  } = dictionary_uiElements;

  const styles = useStyles2(getStyles);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [optionsTags, setOptionsTags] = useState<FilterProps[]>([]);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const filters = getSelectedFiltersInfo(
    selectedStatus,
    selectedFolders,
    selectedMachines,
    selectedTags,
    dictionary_uiElements
  );

  /** Handle tag selection */
  const selectTags = (options: SelectableValue[]) => {
    if (!options.length) {
      dispatch({ type: 'SET_SELECTED_TAGS', payload: options as FilterProps[] });
      return;
    }
    dispatch({ type: 'SET_SELECTED_TAGS', payload: options as FilterProps[] });
  };

  /** Handle machine selection */
  const selectMachines = (machineOptions: SelectableValue[]) => {
    dispatch({ type: 'SET_SELECTED_TAGS', payload: [] });
    if (!machineOptions.length) {
      dispatch({ type: 'SET_SELECTED_MACHINES', payload: [] });
      return;
    }
    dispatch({ type: 'SET_SELECTED_MACHINES', payload: machineOptions as FilterProps[] });
  };

  const getFoldersAndMachinesSelect = () => {
    const machinesAndFoldersSelect = [
      {
        title: TR_selectFolders,
        onChange: filterDashboardsByFolder,
        options: foldersList,
        placeHolder: TR_selectFolderPlaceholder,
        iconName: 'folder' as IconName,
        value: selectedFolders,
      },
      {
        title: TR_selectMachines,
        onChange: (e: any) => selectMachines(e),
        options: machineFiltersOptions,
        placeHolder: TR_selectMachinePlaceholder,
        iconName: 'cog' as IconName,
        value: selectedMachines,
      },
    ];

    return machinesAndFoldersSelect.map((select, index) => (
      <div className={styles.selectContainer} key={`${select.iconName}-${index}`}>
        <div className={styles.labelSelect}>{select.title}</div>
        <MultiSelect
          isClearable
          isSearchable
          maxMenuHeight={150}
          onChange={select.onChange}
          options={select.options}
          placeholder={select.placeHolder}
          prefix={<Icon name={select.iconName} />}
          value={select.value}
          noOptionsMessage={TR_noOption}
        />
      </div>
    ));
  };

  const removeFilter = (iconFilter: string, label: string) => {
    switch (iconFilter) {
      // selected alert state
      case 'bell':
        const selectedStatusCopy = { ...selectedStatus };
        selectedStatusCopy[`${label}`] = false;
        dispatch({ type: 'SET_SELECTED_STATUS', payload: selectedStatusCopy });
        break;
      // selected folders
      case 'folder-open':
        const foldersFiltered = selectedFolders.filter((folder) => folder.label !== label);
        dispatch({ type: 'SET_SELECTED_FOLDERS', payload: foldersFiltered });
        break;

      // selected machines
      case 'cog':
        const machinesFiltered = selectedMachines.filter((machine) => machine.label !== label);
        dispatch({ type: 'SET_SELECTED_MACHINES', payload: machinesFiltered });
        break;

      // selected tags
      case 'tag-alt':
        const tagsFiltered = selectedTags.filter((tag) => tag.label !== label);
        dispatch({ type: 'SET_SELECTED_TAGS', payload: tagsFiltered });
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (tags.length) {
      setOptionsTags(tags);
    }
  }, [tags]); // eslint-disable-line react-hooks/exhaustive-deps

  /** Update tags on selectedDashboards change */
  useEffect(() => {
    if (!selectedTags.length) {
      if (selectedFolders.length || selectedMachines.length) {
        const newTags: FilterProps[] = [];
        for (const dashboard of selectedDashboards) {
          for (const tag of dashboard?.dashboardTags) {
            if (newTags?.findIndex((newtag) => newtag?.value === tag) === -1) {
              newTags.push({ label: tag, value: tag });
            }
          }
        }
        setOptionsTags(newTags);
        return;
      }
      setOptionsTags(tags);
      return;
    }
  }, [selectedDashboards]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.mainContainer}>
      <div className={styles.filtersContainer}>
        <div className={styles.horizontalBar}>
          <div className={styles.filtersTitle} onClick={() => setIsFiltersOpen(!isFiltersOpen)}>
            <ArrowIcons isOpen={isFiltersOpen} />
            <Icon name={'filter'} size={'lg'} style={{ marginRight: '4px' }} />
            {TR_filters?.toUpperCase()}
          </div>
          {/* Display selected filters on fold */}
          {!isFiltersOpen && (
            <div className={styles.filterPillContainer}>
              {filters?.map((filter) =>
                filter?.selection?.map((select) => (
                  <Tooltip content={TR_removeFilter} key={select.label}>
                    <span style={{ marginRight: '4px', whiteSpace: 'nowrap' }}>
                      <FilterPill
                        selected
                        label={select?.label}
                        icon={filter.icon}
                        onClick={() => removeFilter(filter.icon, select.label)}
                      />
                    </span>
                  </Tooltip>
                ))
              )}
            </div>
          )}
        </div>
        {isFiltersOpen && (
          <div style={{ padding: '26px' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
              {/* Select alert state */}
              <div className={styles.selectContainer}>
                <div className={styles.labelSelect}>{TR_alertState}</div>
                <AlertSelectStatus />
              </div>
              {/* Hide table columns */}
              <div className={styles.selectContainer}>
                <div className={styles.labelSelect}>{TR_hideColumns}</div>
                <AlertsColumns theme={theme} />
              </div>
            </div>
            {/* Folders and machines select */}
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>{getFoldersAndMachinesSelect()}</div>
            {/* Select tags */}
            <div style={{ display: 'flex' }}>
              <div className={styles.selectContainer}>
                <div className={styles.labelSelect}>{TR_selectTags}</div>
                <MultiSelect
                  isClearable
                  isSearchable
                  maxMenuHeight={150}
                  maxVisibleValues={3}
                  onChange={(e) => selectTags(e)}
                  options={optionsTags}
                  placeholder={TR_selectTagPlaceholder}
                  prefix={<Icon name={'tag-alt'} />}
                  value={selectedTags}
                  noOptionsMessage={TR_noOption}
                />
              </div>
              {/* Leave empty space right side */}
              <div className={styles.selectContainer}></div>
            </div>
            {/* Save filters button */}
            {user.orgRole !== 'Viewer' && (
              <div style={{ width: '100%', textAlign: 'right' }}>
                <Button onClick={() => setIsModalVisible(true)} variant={'secondary'} size={'md'} icon={'save'}>
                  {TR_saveFilters}
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Confirmation modal */}
      {isModalVisible && <ModalSaveParameters isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    selectContainer: css`
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
      margin: 2px;
      min-width: 277px;
      padding: 4px 32px;
      width: 100%;
    `,
    mainContainer: css`
      background-color: ${theme.isDark ? '#1f1f20' : '#fbfbfb'};
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 8px;
      padding: 12px;
      width: 100%;
    `,
    horizontalBar: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `,
    filtersContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    labelSelect: css`
      font-weight: 500;
    `,
    filtersTitle: css`
      font-size: 16px;
      font-weight: 500;
      padding: 4px 18px 4px 4px;
      cursor: pointer;
      display: flex;
    `,
    filterPillContainer: css`
      display: flex;
      @media (max-width: 768px) {
        display: none;
      }
      overflow: auto;
    `,
    filterPill: css`
      border-radius: 2px;
      padding: 0px 16px 0px 4px;
      font-weight: 500;
      font-size: 12px;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      height: 32px;
      cursor: pointer;
    `,
  };
};
