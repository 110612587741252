import { dateTimeFormat, DateTimeInput, GrafanaTheme2, TypedVariableModel } from '@grafana/data';
import { IconName } from '@grafana/ui';
// @ts-ignore
import cloneDeep from 'lodash.clonedeep';
import { AlertProps, DashboardWithAlerts, FilterProps, SelectStatusProps, TemplatesVar } from '../app-context/types';
import { TranslationProps } from 'app-context/translation-types';
import {
  DANGER_ASYSTOM,
  DANGER_ASYSTOM_LIGHT,
  GREEN_ASYSTOM,
  GREEN_ASYSTOM_LIGHT,
  WARNING_ASYSTOM,
  WARNING_ASYSTOM_LIGHT,
} from './colors';

export const getLanguage = (grafanaVariables: TypedVariableModel[]) => {
  const langSelect = grafanaVariables && grafanaVariables.filter((template) => template.name === TemplatesVar.lang);
  return langSelect;
};

export const groupAlertsByDashboard = (alerts: AlertProps[]) => {
  const groupByDashboard = alerts.reduce((acc: any[], obj: AlertProps, index: number) => {
    if (index === 0) {
      acc.push({ dashboardUid: obj.dashboardUid, alerts: [obj] });
      return acc;
    }

    const indexOfDashboard = acc.findIndex((element) => element.dashboardUid === obj.dashboardUid);
    if (indexOfDashboard !== -1) {
      acc[indexOfDashboard].alerts.push(obj);
      return acc;
    }

    acc.push({ dashboardUid: obj.dashboardUid, alerts: [obj] });
    return acc;
  }, []);

  // count total status of alerts for each dashboard
  for (const dashboard of groupByDashboard) {
    let countOk = 0;
    let countNoData = 0;
    let countAlerting = 0;
    let countPaused = 0;
    let countUnknown = 0;

    if (!dashboard.alerts.length) {
      dashboard.countAlerts = {
        ok: 0,
        noData: 0,
        alerting: 0,
        paused: 0,
        unknown: 0,
      };
      continue;
    }
    for (const alert of dashboard.alerts) {
      alert?.status === 'ok' && (countOk += 1);
      alert?.status === 'no_data' && (countNoData += 1);
      alert?.status === 'alerting' && (countAlerting += 1);
      alert?.status === 'paused' && (countPaused += 1);
      alert?.status === 'unknown' && (countUnknown += 1);
    }
    dashboard.countAlerts = {
      ok: countOk,
      noData: countNoData,
      alerting: countAlerting,
      paused: countPaused,
      unknown: countUnknown,
    };
  }

  return groupByDashboard;
};

export const filterDashboardByAlertStatus = (dashboards: DashboardWithAlerts[], selectedStatus: SelectStatusProps) => {
  const newDashboards = [] as any;

  for (let dashboard of dashboards) {
    const newDashboard = cloneDeep(dashboard);
    const newAlerts = [] as AlertProps[];

    if (!newDashboard.alerts.length) {
      continue;
    }

    for (const alert of newDashboard.alerts) {
      if (alert?.status === 'ok' && selectedStatus.ok) {
        newAlerts.push(alert);
        continue;
      }
      if (alert?.status === 'alerting' && selectedStatus.alerting) {
        newAlerts.push(alert);
        continue;
      }
      if (alert?.status === 'no_data' && selectedStatus.noData) {
        newAlerts.push(alert);
        continue;
      }
      if (alert?.status === 'paused' && selectedStatus.paused) {
        newAlerts.push(alert);
        continue;
      }
      if (alert?.status === 'pending' || alert?.status === 'unknown') {
        newAlerts.push(alert);
        continue;
      }
    }

    if (!selectedStatus.ok) {
      newDashboard.countAlerts.ok = 0;
    }
    if (!selectedStatus.noData) {
      newDashboard.countAlerts.noData = 0;
    }
    if (!selectedStatus.alerting) {
      newDashboard.countAlerts.alerting = 0;
    }
    if (!selectedStatus.paused) {
      newDashboard.countAlerts.paused = 0;
    }
    newDashboard.alerts = newAlerts;
    newDashboards.push(newDashboard);
  }
  return newDashboards;
};

export const getAlertsCounters = (dashboardAlerts: DashboardWithAlerts[]) => {
  let countOk = 0;
  let countNoData = 0;
  let countAlerting = 0;
  let countPaused = 0;
  let countUnknown = 0;
  const countAlerts = {
    ok: countOk,
    noData: countNoData,
    alerting: countAlerting,
    paused: countPaused,
    unknown: countUnknown,
  };

  for (const dashboard of dashboardAlerts) {
    countAlerts.ok += dashboard.countAlerts.ok;
    countAlerts.noData += dashboard.countAlerts.noData;
    countAlerts.alerting += dashboard.countAlerts.alerting;
    countAlerts.unknown += dashboard.countAlerts.unknown;
    countAlerts.paused += dashboard.countAlerts.paused;
  }
  return countAlerts;
};

export const getMachinesInSelectedFolders = (newDashboards: DashboardWithAlerts[], machineFilters: FilterProps[]) => {
  const newMachines = [] as FilterProps[];

  // Get machine filters in the selected folder(s)
  for (const dashboard of newDashboards) {
    const findMachine = machineFilters.findIndex((el) => el.value === dashboard.machineId);
    // find machine from selected folders and push it to the new machine options if it doesn't already exist
    if (findMachine !== -1 && newMachines.findIndex((el) => el.value === dashboard.machineId) === -1) {
      newMachines.push(machineFilters[findMachine]);
    }
  }
  return newMachines;
};

export const getStateDisplayModel = (state: string, theme?: GrafanaTheme2) => {
  let stateModel = state;

  if (!state) {
    stateModel = 'unknown';
  }

  if (state === 'noData') {
    stateModel = 'no_data';
  }

  switch (stateModel) {
    case 'ok': {
      return {
        bgColor: GREEN_ASYSTOM,
        bgColorLight: GREEN_ASYSTOM_LIGHT,
        txtColor: '#00704b',
        iconClass: 'check-circle' as IconName,
        stateClass: 'alert-state-ok',
        text: 'OK',
      };
    }
    case 'alerting': {
      return {
        bgColor: DANGER_ASYSTOM,
        bgColorLight: DANGER_ASYSTOM_LIGHT,
        txtColor: '#c15946',
        iconClass: 'exclamation-triangle' as IconName,
        stateClass: 'alert-state-critical',
        text: 'ALERTING',
      };
    }
    case 'no_data': {
      return {
        bgColor: WARNING_ASYSTOM,
        bgColorLight: WARNING_ASYSTOM_LIGHT,
        txtColor: '#c19a35',
        iconClass: 'question-circle' as IconName,
        stateClass: 'alert-state-warning',
        text: 'NO DATA',
      };
    }
    case 'paused': {
      return {
        bgColor: '#8e8e8e',
        bgColorLight: '#ededed',
        txtColor: theme && theme.colors.text.primary,
        iconClass: 'pause' as IconName,
        stateClass: 'alert-state-paused',
        text: 'PAUSED',
      };
    }
    case 'pending': {
      return {
        bgColor: '#8e8e8e',
        bgColorLight: '#ededed',
        txtColor: theme && theme.colors.text.primary,
        iconClass: 'hourglass' as IconName,
        stateClass: 'alert-state-paused',
        text: 'PENDING',
      };
    }
    default: {
      return {
        bgColor: '#8e8e8e',
        bgColorLight: '#ededed',
        txtColor: theme && theme.colors.text.primary,
        iconClass: 'question-circle' as IconName,
        stateClass: 'alert-state-paused',
        text: 'UNKNOWN',
      };
    }
  }

  throw { message: 'Unknown alert state' };
};

export const getSelectedFiltersInfo = (
  selectedStatus: SelectStatusProps,
  selectedFolders: FilterProps[],
  selectedMachines: FilterProps[],
  selectedTags: FilterProps[],
  dico: TranslationProps
) => {
  const { TR_selectedAlertStates, TR_selectedFolders, TR_selectedMachines, TR_selectedTags } = dico;
  const varAlertStates: string[] = [];
  for (const [key, value] of Object.entries(selectedStatus)) {
    if (value) {
      varAlertStates.push(key);
    }
  }
  const varFolders = selectedFolders?.map((folder) => `${folder.value} : ${folder.label}`)?.join(',');
  const varMachines = selectedMachines?.map((machine) => machine.value)?.join(',');
  const varTags = selectedTags?.map((tag) => tag.value)?.join(',');

  const filters = [
    {
      selection: varAlertStates.map((state) => ({ label: state })),
      icon: 'bell' as IconName,
      text: TR_selectedAlertStates,
      varLabel: 'alert states',
      varName: 'alertstate_selection',
      varValues: varAlertStates.join(','),
    },
    {
      selection: selectedFolders,
      icon: 'folder-open' as IconName,
      text: TR_selectedFolders,
      varLabel: 'folders',
      varName: 'folders_selection',
      varValues: varFolders,
    },
    {
      selection: selectedMachines,
      icon: 'cog' as IconName,
      text: TR_selectedMachines,
      varLabel: 'machines',
      varName: 'machines_selection',
      varValues: varMachines,
    },
    {
      selection: selectedTags,
      icon: 'tag-alt' as IconName,
      text: TR_selectedTags,
      varLabel: 'tags',
      varName: 'tags_selection',
      varValues: varTags,
    },
  ];

  return filters;
};

export const joinEvalMatches = (matches: any, separator: string) => {
  return matches
    .reduce((res: any[], ev: any) => {
      if (ev.metric !== undefined && ev.value !== undefined) {
        res.push(ev.metric + '=' + ev.value);
      }

      // For backwards compatibility . Should be be able to remove this after ~2017-06-01
      if (ev.Metric !== undefined && ev.Value !== undefined) {
        res.push(ev.Metric + '=' + ev.Value);
      }

      return res;
    }, [])
    .join(separator);
};

export const getAlertAnnotationInfo = (alert: any) => {
  if (Array.isArray(alert.data)) {
    return joinEvalMatches(alert.data, ', ');
  } else if (Array.isArray(alert?.data?.evalMatches)) {
    return joinEvalMatches(alert.data.evalMatches, ', ');
  }

  if (alert.data.error) {
    return 'Error: ' + alert.data.error;
  }
  return '';
};

export const filterByMachine = (originArray: DashboardWithAlerts[], filters: FilterProps[]) => {
  if (!originArray.length || !filters.length) {
    return [];
  }
  let newDashboards: DashboardWithAlerts[] = [];
  for (const dashboard of originArray) {
    const findIndex = filters.findIndex((filter) => filter.value === dashboard.machineId);
    if (findIndex !== -1) {
      newDashboards.push(dashboard);
    }
  }
  return newDashboards;
};

export const filterByFolder = (originArray: DashboardWithAlerts[], filters: FilterProps[]) => {
  if (!originArray.length || !filters.length) {
    return [];
  }
  let newDashboards: DashboardWithAlerts[] = [];
  for (const dashboard of originArray) {
    const findIndex = filters.findIndex((filter) => filter.value === dashboard.folderId);
    if (findIndex !== -1) {
      newDashboards.push(dashboard);
    }
  }
  return newDashboards;
};

export const filterByTag = (originArray: DashboardWithAlerts[], filters: FilterProps[]) => {
  if (!originArray.length || !filters.length) {
    return [];
  }
  let newDashboards = originArray.filter(
    (dashboard) =>
      dashboard.dashboardTags.filter((tag) =>
        filters.findIndex((selectedtag) => selectedtag.value === tag) !== -1 ? true : false
      ).length
  );
  return newDashboards;
};

export const escapeSpecialChars = (value: string) => {
  if (!value) {
    return '';
  }
  return value
    .replace(/"/g, '\\"')
    .replace(/'/g, "\\'")
    .replace(/,/g, '\\,')
    .replace(/;/g, '\\;')
    .replace(/\(/g, '\\(')
    .replace(/\)/g, '\\)')
    .replace(/</g, '\\<')
    .replace(/>/g, '\\>')
    .trim();
};

export const formatDate = (date: DateTimeInput, timeZone: string, format?: string) => {
  return dateTimeFormat(date, {
    format,
    timeZone: timeZone,
  });
};
