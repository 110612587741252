import React from 'react';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { useAppState } from '../../app-context/AppStateContext';

interface AlertsColumnsProps {
  theme: GrafanaTheme2;
}

export const AlertsColumns: React.FunctionComponent<AlertsColumnsProps> = ({ theme }) => {
  const { state, dispatch } = useAppState();
  const { alertsColumns } = state;
  const styles = useStyles2(getStyles);

  const toggleColumns = (colIndex: number) => {
    const newColumns = [...alertsColumns];
    newColumns[colIndex].isChecked = !newColumns[colIndex].isChecked;
    dispatch({ type: 'SET_ALERTS_COLUMNS', payload: newColumns });
  };

  return (
    <div className={styles.wrapper}>
      {alertsColumns &&
        alertsColumns.map((alert, index) => (
          <div
            key={`${alert.Header}-${index}`}
            className={styles.selectButton}
            style={{ backgroundColor: alert.isChecked ? theme.colors.background.secondary : '' }}
            onClick={() => toggleColumns(index)}>
            {alert.Header}
          </div>
        ))}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      border-radius: 2px;
      border: 1px solid ${theme.colors.border.medium};
      display: flex;
      padding: 2px;
      width: 100%;
      flex-wrap: wrap;
    `,
    selectButton: css`
      border-radius: 2px;
      cursor: pointer;
      margin: 1px;
      padding: 2px 21px;
      text-align: center;
      font-size: 12px;
      flex: 1;
      white-space: nowrap;
    `,
    alertsColumnContainer: css`
      align-items: flex-start;
      justify-content: center;
      display: flex;
      flex-direction: column;
    `,
  };
};
