import { Icon, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { MacAddressProps } from 'app-context/types';
import { css } from '@emotion/css';
import React from 'react';
import { removeOneOtherTag } from './helpersFilters';

interface BadgeTagProps {
  macAddresses: MacAddressProps[];
  mac: MacAddressProps;
}

export const BadgeTag: React.FunctionComponent<BadgeTagProps> = ({ macAddresses, mac }) => {
  const { dispatch, state } = useAppState();
  const { dictionary_uiElements } = state;
  const styles = useStyles2(getStyles);

  const removeTag = (mac: MacAddressProps, tag: string) => {
    const newMacAddresses = removeOneOtherTag(macAddresses, mac, tag);
    dispatch({ type: 'SET_MAC_ADDRESSES', payload: newMacAddresses });
  };

  return (
    <div
      className={css`
        display: flex;
        flex-direction: row;
        width: 100%;
        flex-wrap: wrap;
        padding-left: 8px;
        justify-content: flex-start;
      `}>
      {mac.otherTags
        .filter((tag) => tag.label.toLowerCase() !== 'machine')
        .map((tag) => (
          <span
            className={styles.othertags}
            onClick={() => removeTag(mac, tag.label)}
            key={`${tag.label} - ${mac.dashboardUid}`}>
            <Tooltip content={dictionary_uiElements.TR_delete} placement={'right'}>
              <div className={styles.badge}>
                {tag.label}
                <Icon name={'times'} />
              </div>
            </Tooltip>
          </span>
        ))}
    </div>
  );
};

const getStyles = () => {
  return {
    othertags: css`
      max-width: 185px;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 3px;
      margin-bottom: 3px;
    `,
    badge: css`
      font-size: 12px;
      display: inline-flex;
      padding: 2px 10px;
      border-radius: 13px;
      background: #ffe5d3;
      color: #633200;
      cursor: pointer;
      &:hover {
        background: #e02f4426;
        color: #a9192a;
      }
    `,
  };
};
