import React, { useState, useEffect } from 'react';
import { useStyles2, Icon, Button } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { css } from '@emotion/css';
import { useAppState } from 'app-context/AppStateContext';
import { LastAlertReceivedProps } from 'app-context/types';
import { LastAlertsListDrawer } from './LastAlertsListDrawer';
import { AlertDetailsModal } from '../alert-details/AlertDetailsModal';
import { AlertCardContent } from './AlertCardContent';

interface LastAlertsProps {}

const LIMIT_LAST_ALERTS = 11;

export const LastAlerts: React.FunctionComponent<LastAlertsProps> = () => {
  const { state } = useAppState();
  const { selectedDashboards, dictionary_uiElements } = state;
  const { TR_seeLastAlerts } = dictionary_uiElements;

  const [alertIndex, setAlertIndex] = useState(0);
  const [lastAlerts, setLastAlerts] = useState<LastAlertReceivedProps[]>([]);
  const [isLastAlertModalVisible, setIsLastAlertModalVisible] = useState(false);
  const alertNewStateDate = lastAlerts && lastAlerts[alertIndex]?.newStateDate;
  const [isModalAlertVisible, setIsModalAlertVisible] = useState(false);
  const [cellInfo, setCellInfo] = useState({ details: {}, cellIndex: 0 });

  const styles = useStyles2(getStyles);

  const getNavigationIcon = (position: string) => {
    switch (position) {
      case 'left':
        return (
          <div className={styles.arrow} onClick={() => setAlertIndex(alertIndex - 1)}>
            {alertIndex !== 0 && <Icon name={'angle-left'} size={'xxxl'} />}
          </div>
        );

      case 'right':
        return (
          <div className={styles.arrow} onClick={() => setAlertIndex(alertIndex + 1)}>
            <Icon name={'angle-right'} size={'xxxl'} />
          </div>
        );

      default:
        return;
    }
  };

  const getLastAlerts = async () => {
    let currentAlertDisplayed: number;
    // Last alerts are retrieved on each refresh or on dashboard selection (via filters), we need to keep the current alert id displayed
    if (lastAlerts.length) {
      currentAlertDisplayed = lastAlerts[alertIndex].alertId;
    }

    const dashboardAlerts: LastAlertReceivedProps[] = [];
    for (const dashboard of selectedDashboards) {
      dashboard.alerts.map((alert) =>
        dashboardAlerts.push({
          dashboardName: dashboard.dashboardTitle,
          machine: dashboard.machineId,
          macAddress: dashboard.dashboardMacAddress,
          ...alert,
        })
      );
    }
    const getLastDashboardAlerts = dashboardAlerts
      .sort((alert1, alert2) => (alert1.newStateDate < alert2.newStateDate ? 1 : -1))
      .filter(
        (alert) => selectedDashboards.findIndex((dashboard) => dashboard.dashboardUid === alert.dashboardUid) !== -1
      )
      .filter((alert, index) => index < LIMIT_LAST_ALERTS);
    setLastAlerts(getLastDashboardAlerts);

    if (getLastDashboardAlerts.length) {
      const newIndex = getLastDashboardAlerts.findIndex((dashboard) => dashboard.alertId === currentAlertDisplayed);
      setAlertIndex(newIndex !== -1 ? newIndex : 0);
    }
  };

  const openModal = (cell: any) => {
    setIsModalAlertVisible(!isModalAlertVisible);
    if (!isModalAlertVisible) {
      setCellInfo({ ...cellInfo, details: cell });
    }
  };

  useEffect(() => {
    if (selectedDashboards.length) {
      getLastAlerts();
      return;
    }
    setLastAlerts([]);
  }, [selectedDashboards]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.lastAlertContainer}>
      {/* "See last alerts" button */}
      <div style={{ padding: '5px 0', marginRight: '25px' }}>
        {lastAlerts.length !== 0 && (
          <Button
            variant={'secondary'}
            fill={'text'}
            icon={'history'}
            onClick={() => setIsLastAlertModalVisible(true)}
            size={'md'}>
            {TR_seeLastAlerts}
          </Button>
        )}
      </div>
      <div className={styles.cardContainer}>
        {alertIndex !== -1 && (
          <div className={styles.alertPanel}>
            {/* Left arrow */}
            <div style={{ width: '48px' }}>{getNavigationIcon('left')}</div>
            {/* Alert card  content */}
            <AlertCardContent
              lastAlert={lastAlerts[alertIndex]}
              alertIndex={alertIndex}
              openModal={openModal}
              setCellInfo={setCellInfo}
            />
            {/* Right arrow */}
            <div style={{ width: '48px' }}>
              {alertIndex !== lastAlerts?.length - 1 && lastAlerts.length !== 0 && getNavigationIcon('right')}
            </div>
          </div>
        )}
      </div>

      {/* Display last alerts list */}
      {isLastAlertModalVisible && (
        <div
          className={css`
            width: 90%;
          `}>
          <LastAlertsListDrawer
            setIsModalVisible={setIsLastAlertModalVisible}
            alertNewStateDate={alertNewStateDate}
            lastAlerts={lastAlerts}
          />
        </div>
      )}
      {isModalAlertVisible && cellInfo && <AlertDetailsModal cellInfo={cellInfo} openModal={openModal} />}
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    lastAlertContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 0 16px;
      max-width: 620px;
      align-items: flex-end;
    `,
    lastAlertsTitle: css`
      font-size: 16px;
      font-weight: 500;
      padding: 5px 0;
    `,
    alertPanel: css`
      align-items: center;
      display: flex;
      border-radius: 4px;
      flex: 2;
      height: 100%;
    `,
    alertTitle: css`
      font-size: 18px;
      font-weight: 600;
      padding-right: 5px;
      cursor: pointer;
      max-width: 450px;
      overflow: hidden;
      max-width: 374px;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    badge: css`
      font-size: 14px;
      display: inline-flex;
      padding: 4px 6px;
      border-radius: 4px;
      display: inline;
      margin-top: 5px;
      @media (max-width: 861px) {
        display: none;
      }
    `,
    arrow: css`
      cursor: pointer;
      display: flex;
      height: 100%;
      align-items: center;
      color: ${theme.colors.text.secondary};
      &:hover {
        color: ${theme.colors.primary.text};
      }
    `,
    iconContainer: css`
      padding: 6px;
      display: flex;
      align-items: center;
      cursor: pointer;
    `,
    centralPart: css`
      display: flex;
      flex-direction: column;
      padding: 8px 18px;
      width: 100%;
      color: ${'#424345'};
    `,
    cardContainer: css`
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      height: 110px;
    `,
    cardContent: css`
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 100%;
    `,
    cardContentNoAlert: css`
      display: flex;
      width: 100%;
      border: 1px solid ${theme.colors.border.medium};
    `,
    noAlert: css`
      height: 58px;
      font-style: italic;
      padding: 8px;
      text-align: center;
      width: 100%;
    `,
    alertDetailsLine: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: start;
      flex-wrap: wrap;
    `,
  };
};
