import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import { formatDate } from 'helpers/helpersFilters';
import { getBackendSrv } from '@grafana/runtime';
import { useAppState } from 'app-context/AppStateContext';
import { AnnotationProps } from 'app-context/types';
import { Icon, useStyles2 } from '@grafana/ui';
import { GrafanaTheme2 } from '@grafana/data';
import { ArrowIcons } from 'shared/ArrowIcons';

interface AlertAnnotationProps {
  dashboardId: number;
  panelId: number;
}

export const AlertAnnotation: React.FunctionComponent<AlertAnnotationProps> = ({ dashboardId, panelId }) => {
  const { state } = useAppState();
  const { user, dictionary_uiElements } = state;
  const { TR_annotations, TR_noAnnotation } = dictionary_uiElements;
  const styles = useStyles2(getStyles);

  const [annotations, setAnnotations] = useState<AnnotationProps[]>([]);
  const [isAnnotationOpen, setIsAnnotationsOpen] = useState(false);

  const getAnnotations = () => {
    getBackendSrv()
      .get(`/api/annotations?dashboardId=${dashboardId}&panelId=${panelId}&limit=50&type=annotation`)
      .then((data) => {
        const items = data.map((item: any) => ({
          created: formatDate(item.created, user.timeZone, 'MMM D, YYYY HH:mm:ss'),
          text: item.text,
          time: formatDate(item.time, user.timeZone, 'MMM D, YYYY HH:mm:ss'),
          login: item.login,
        }));
        setAnnotations(items);
      });
  };

  useEffect(() => {
    getAnnotations();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.historyList}>
      <div className={styles.foldableRow} onClick={() => setIsAnnotationsOpen(!isAnnotationOpen)}>
        <ArrowIcons isOpen={isAnnotationOpen} />
        <Icon name={'edit'} style={{ marginRight: '6px' }} />
        {TR_annotations} ({annotations.length})
      </div>
      {isAnnotationOpen && (
        <div className={styles.stateHistoryContainer}>
          <ol className="alert-rule-list">
            {annotations.length !== 0 ? (
              annotations.map((item, index) => (
                <AlertAnnotationItem item={item} index={index} key={`${index}-${item.created}`} />
              ))
            ) : (
              <i>{TR_noAnnotation}</i>
            )}
          </ol>
        </div>
      )}
    </div>
  );
};

interface AlertAnnotationItemProps {
  item: AnnotationProps;
  index: number;
}

const AlertAnnotationItem: React.FunctionComponent<AlertAnnotationItemProps> = ({ item, index }) => {
  const { state } = useAppState();
  const { dictionary_uiElements } = state;
  /** UI translation */
  const { TR_created, TR_by } = dictionary_uiElements;

  return (
    <li style={{ flexWrap: 'wrap' }} className={'alert-rule-item'} key={`${item.time}-${index}`}>
      <div className="alert-rule-item__body">
        <div className="alert-rule-item__header">
          <div className="alert-rule-item__text">
            <span>Position {item.time}</span>
          </div>
        </div>
        {item.text}
      </div>
      <div className="alert-rule-item__time">
        {TR_created} {item.created} {TR_by} {item.login}
      </div>
    </li>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    stateHistoryContainer: css`
      padding: 12px;
    `,
    historyList: css`
      padding: 12px;
      border: 1px solid ${theme.colors.border.weak};
      border-radius: 4px;
      margin-bottom: 6px;
    `,
    foldableRow: css`
      cursor: pointer;
      display: flex;
      font-weight: 500;
    `,
  };
};
