import { GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Icon, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { AlertMetricValue } from 'app-context/types';
import { css } from '@emotion/css';
import { DANGER_ASYSTOM, GREEN_ASYSTOM } from 'helpers/colors';
import { getStateDisplayModel } from 'helpers/helpersFilters';
import React, { useEffect, useState } from 'react';

interface AlertInfoHeaderProps {
  cellInfo: any;
}

export const AlertDetailsHeader: React.FunctionComponent<AlertInfoHeaderProps> = ({ cellInfo }) => {
  const { state, dispatch } = useAppState();
  const { dictionary_uiElements, selectedDashboards } = state;

  /** UI translation */
  const [alertValue, setAlertValue] = useState('');
  const { TR_vibratorySeverity, TR_battery, TR_anomalyScore, TR_for, TR_openClosedState } = dictionary_uiElements;

  const dashboardName = React.useMemo(
    () => (cellInfo?.row?.values?.dashboardName?.length ? cellInfo?.row?.values?.dashboardName[0] : ''),
    [cellInfo]
  );
  const alertName = React.useMemo(() => cellInfo?.column?.Header?.toUpperCase(), [cellInfo]);
  const macAddress = React.useMemo(
    () => (cellInfo?.row?.values?.dashboardName?.length ? cellInfo?.row?.values?.dashboardName[2] : ''),
    [cellInfo]
  );
  const alertStateDate = React.useMemo(() => (cellInfo?.value?.length ? cellInfo?.value[3] : ''), [cellInfo]);
  const alertStatus = React.useMemo(() => (cellInfo?.value?.length ? cellInfo?.value[1] : 'unknown'), [cellInfo]);
  const colorStatus = React.useMemo(
    () => (alertStatus ? getStateDisplayModel(alertStatus)?.bgColor : getStateDisplayModel('unknown').bgColor),
    [alertStatus] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const styles = useStyles2(getStyles);

  /**
   * Alerted value is provided by the alerts api contrary to the 'OK' value.
   * Instead of making complex requests, we use the test api to retrieve this value,
   * that's why this function is only used when alert status is OK.
   */
  const fetchAlertValue = async () => {
    const dashboardId = cellInfo?.row?.allCells[0].value[4];
    const findModelIndex = selectedDashboards.findIndex((dashboard) => dashboard.dashboardId === dashboardId);
    if (findModelIndex === -1) {
      return;
    }
    const model = selectedDashboards[findModelIndex].dashboardModel;
    const panelId = cellInfo.value[4];

    // send dashboard json model to test alert
    const newDashboards = [...selectedDashboards];
    const findAlertIndex = newDashboards.findIndex((dashboard) => dashboard.dashboardId === dashboardId);

    const payload = { dashboard: model, panelId: panelId };
    const alertIndex = newDashboards[findAlertIndex].alerts.findIndex((alert) => alert.panelId === panelId);
    const testRuleResponse = await getBackendSrv().post(`/api/alerts/test`, payload);

    if (testRuleResponse.state === 'ok') {
      const testValue = testRuleResponse.logs[2].message.split('Value: ')[1];

      if (alertName?.toUpperCase() === TR_openClosedState?.toUpperCase()) {
        setAlertValue(parseFloat(testValue.trim()) > 0.5 ? 'OPEN' : 'CLOSED');
      } else {
        setAlertValue(parseFloat(testValue?.trim()).toFixed(2).toString());
      }

      newDashboards[findAlertIndex].alerts[alertIndex].alertValue = parseFloat(testValue?.trim()).toString();
    }

    if (testRuleResponse.state === 'pending' && testRuleResponse.firing) {
      if (alertName?.toUpperCase() === TR_openClosedState?.toUpperCase()) {
        newDashboards[findAlertIndex].alerts[alertIndex].alertValue = testRuleResponse?.matches[0]?.value;
        setAlertValue(parseFloat(testRuleResponse?.matches[0]?.value.trim()) > 0.5 ? 'OPEN' : 'CLOSED');
      } else {
        newDashboards[findAlertIndex].alerts[alertIndex].alertValue = testRuleResponse?.matches[0]?.value;
        setAlertValue(testRuleResponse?.matches[0]?.value);
      }
    }
    dispatch({ type: 'SET_SELECTED_DASHBOARDS', payload: newDashboards });
  };

  const getAlertValue = () => {
    const dashboardId = cellInfo?.row?.allCells[0].value[4];
    const findVarLengthUnit = selectedDashboards.findIndex((dashboard) => dashboard.dashboardId === dashboardId);

    if (findVarLengthUnit === -1) {
      return '';
    }

    const lengthUnit = selectedDashboards[findVarLengthUnit].lengthUnit;

    if (alertStatus !== 'no_data') {
      let unit = '';

      switch (alertName?.toUpperCase()) {
        case TR_vibratorySeverity?.toUpperCase():
          unit = lengthUnit === 'inch' ? 'in/s' : 'mm/s';
          break;

        case TR_battery?.toUpperCase():
          unit = 'V';
          break;

        case TR_anomalyScore?.toUpperCase():
          unit = '%';

        default:
          break;
      }

      if (alertStatus === 'alerting' || alertStatus === 'pending') {
        const values = cellInfo && cellInfo?.value[0] && JSON.parse(cellInfo?.value[0]);
        return (
          <div className={styles.displayValue} style={{ color: alertStatus === 'alerting' ? DANGER_ASYSTOM : '' }}>
            {values?.map((alert: AlertMetricValue, index: number) => (
              <div key={`${alert.metric} ${index}`}>
                <span>{alert?.metric?.toLowerCase()}: </span>
                <span>
                  {alert?.value?.toFixed(2)} {unit}
                </span>
              </div>
            ))}
          </div>
        );
      }

      if (alertStatus === 'paused' || alertStatus === 'unknown') {
        return '';
      }
      return (
        <span style={{ color: GREEN_ASYSTOM, fontSize: '16px', fontWeight: 500 }}>
          {parseInt(alertValue, 10)?.toFixed(2)} {unit}
        </span>
      );
    }
    return '';
  };

  useEffect(() => {
    if (alertStatus === 'ok') {
      fetchAlertValue();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.frameInfoContainer}>
      <div className={styles.headerTop}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontSize: '22px', fontWeight: 600 }}>{alertName}</div>
          <div className={styles.bold}>{dashboardName?.toUpperCase()}</div>
          <div className={styles.bold}>{macAddress?.toUpperCase()}</div>
        </div>
        <div className={styles.statusContainer}>
          <div className={getStateDisplayModel(alertStatus).stateClass} style={{ color: colorStatus }}>
            <Tooltip content={alertStatus} placement={'left'}>
              <Icon name={getStateDisplayModel(alertStatus).iconClass} size={'xxxl'} />
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styles.headerBottom}>
        {alertStatus !== 'paused' && alertStatus !== 'unknown' && (
          <div>
            {TR_for} {alertStateDate}
          </div>
        )}
        <div style={{ fontSize: '16px', fontWeight: 500 }}>{getAlertValue()}</div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    frameInfoContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      background-color: ${theme.isDark ? '#2f2f32' : '#f4f5f8'};
      padding: 12px;
    `,
    bold: css`
      font-weight: 500;
    `,
    statusContainer: css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `,
    headerTop: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    `,
    headerBottom: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
    `,
    displayValue: css`
      display: flex;
      flex-direction: column;
      font-size: 16px;
      align-items: flex-end;
      font-weight: 500;
    `,
  };
};
