import { GrafanaTheme2 } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { Icon, IconName, useStyles2 } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import { formatDate, getAlertAnnotationInfo, getStateDisplayModel } from 'helpers/helpersFilters';
import React, { useEffect, useState } from 'react';
import { ArrowIcons } from 'shared/ArrowIcons';

interface AlertStateHistoryProps {
  dashboardId: number;
  panelId: number;
}

export const AlertStateHistory: React.FunctionComponent<AlertStateHistoryProps> = ({ dashboardId, panelId }) => {
  const { state } = useAppState();
  const { user, dictionary_uiElements } = state;
  const { TR_stateHistory, TR_noHistoryState } = dictionary_uiElements;

  const [isListOpen, setIsListOpen] = useState(false);
  const [stateHistoryItems, setStateHistoryItems] = useState<HistoryAlertItemProps[]>([]);
  const styles = useStyles2(getStyles);

  const getAlertItems = () => {
    getBackendSrv()
      .get(
        `/api/annotations?dashboardId=${dashboardId}&panelId=${panelId}&limit=50&type=alert`,
        {},
        `state-history-${dashboardId}-${panelId}`
      )
      .then((data) => {
        const items = data.map((item: any) => {
          return {
            stateModel: getStateDisplayModel(item.newState),
            time: formatDate(item.time, user.timeZone, 'MMM D, YYYY HH:mm:ss'),
            info: getAlertAnnotationInfo(item),
          };
        });
        setStateHistoryItems(items);
      });
  };

  useEffect(() => {
    getAlertItems();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.historyList}>
      <div className={styles.foldableRow} onClick={() => setIsListOpen(!isListOpen)}>
        <ArrowIcons isOpen={isListOpen} />
        <Icon name={'history'} style={{ marginRight: '6px' }} />
        {TR_stateHistory} ({stateHistoryItems.length})
      </div>
      {isListOpen && (
        <div className={styles.stateHistoryContainer}>
          <ol className="alert-rule-list">
            {stateHistoryItems.length !== 0 ? (
              stateHistoryItems.map((item, index) => (
                <AlertItem item={item} index={index} key={`${item.time}-${index}`} />
              ))
            ) : (
              <i>{TR_noHistoryState}</i>
            )}
          </ol>
        </div>
      )}
    </div>
  );
};

interface HistoryAlertItemProps {
  stateModel: {
    text: string;
    iconClass: IconName;
    stateClass: string;
  };
  time: string;
  info: string;
}

interface AlertItemProps {
  item: HistoryAlertItemProps;
  index: number;
}

const AlertItem: React.FunctionComponent<AlertItemProps> = ({ item, index }) => {
  return (
    <li className="alert-rule-item" key={`${item.time}-${index}`}>
      <div className={`alert-rule-item__icon ${item.stateModel.stateClass}`}>
        <Icon name={item.stateModel.iconClass} size="xl" />
      </div>
      <div className="alert-rule-item__body">
        <div className="alert-rule-item__header">
          <div className="alert-rule-item__text">
            <span className={`${item.stateModel.stateClass}`}>{item.stateModel.text}</span>
          </div>
        </div>
        {item.info}
      </div>
      <div className="alert-rule-item__time">{item.time}</div>
    </li>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    modal: css`
      position: fixed;
      z-index: 1001;
      padding-top: 100px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background-color: #00000094;
    `,
    modalContent: css`
      background-color: ${theme.colors.background.primary};
      margin: auto;
      padding: 20px;
      border: 1px solid ${theme.colors.border.weak};
      width: 65%;
      height: 80%;
      @media (max-width: 768px) {
        width: 95%;
        height: 80%;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;
    `,
    buttonContainer: css`
      display: flex;
      padding: 8px 0;
      width: 100%;
      justify-content: space-between;
    `,
    stateHistoryContainer: css`
      padding: 12px;
    `,
    infoContainer: css`
      display: flex;
      justify-content: flex-start;
      width: 100%;
      flex-direction: column;
      overflow: auto;
      margin-bottom: 8px;
    `,
    historyList: css`
      padding: 12px;
      border: 1px solid ${theme.colors.border.medium};
      border-radius: 4px;
      margin-bottom: 6px;
    `,
    alertExtraInfo: css`
      display: flex;
      flex-direction: column;
      max-height: 420px;
      overflow: auto;
      padding: 12px;
    `,
    foldableRow: css`
      cursor: pointer;
      display: flex;
      font-weight: 500;
    `,
  };
};
