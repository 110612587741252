import { GrafanaTheme2 } from '@grafana/data';
import { Button, Modal, Spinner, useStyles2, Switch } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { useMainContainer } from 'hooks/useMainContainer';
import { MainContainerProps } from 'app-context/types';
import { css } from '@emotion/css';
//@ts-ignore
import cloneDeep from 'lodash.clonedeep';
import React, { useState } from 'react';
import { AlertTableContainer, FilterSection, FoldersAndMachines, LastAlerts, MachineFilter, MainTitle } from '.';
import { CountDashboardsPie } from './CountDashboardsPie';
import { FiltersManagement } from './filters/FiltersManagement';

export const MainContainer: React.FunctionComponent<MainContainerProps> = ({ data }) => {
  const { state } = useAppState();
  const { dictionary_uiElements, selectedDashboards, selectedFolders, selectedMachines, selectedTags, theme, user } =
    state;
  const { TR_collapseFolders, TR_expandFolders, TR_manageMachineTags, TR_syntheticDashboard } = dictionary_uiElements;
  // Dropdown menu of machine filters depends of the folders selected, it's necessary to keep the original machine filters array unmutable
  const [isUnfold, setIsUnfold] = useState(false);
  const [isFiltersMgmtOpen, setIsFiltersMgmtOpen] = useState(false);
  const {
    dashboardsFiltered,
    filterDashboardsByFolder,
    foldersList,
    getTotalDashboardAlerts,
    isLoading,
    machineFiltersOptions,
  } = useMainContainer(data);

  const styles = useStyles2(getStyles);

  return (
    <div className={styles.mainContainer}>
      <div className={styles.container}>
        <MainTitle />
        <div className={styles.headerContainer}>
          {!isLoading && <CountDashboardsPie />}
          {!isLoading && <LastAlerts />}
        </div>
        <FilterSection
          filterDashboardsByFolder={filterDashboardsByFolder}
          foldersList={foldersList}
          machineFiltersOptions={machineFiltersOptions}
        />
        {/* Conditional rendering of switch button (expand / collapse folders) */}
        {isLoading && (
          <div className={styles.loadingContainer}>
            <div>Loading dashboards</div>
            <div>
              <Spinner />
            </div>
          </div>
        )}
        {!isLoading && (
          <div className={styles.dashboardsContainer}>
            <div className={styles.secondaryHeader}>
              <div className={styles.unfoldFeatureContainer}>
                {(((selectedFolders.length !== 0 || selectedMachines.length !== 0) && !selectedTags.length) ||
                  (!selectedFolders.length && !selectedMachines.length && !selectedTags.length) ||
                  (selectedFolders.length !== 0 && selectedMachines.length !== 0 && selectedTags.length !== 0)) &&
                  selectedDashboards.reduce((acc, current) => (acc += current.alerts.length), 0) !== 0 && (
                    <>
                      <div style={{ fontWeight: 500 }}>{isUnfold ? TR_collapseFolders : TR_expandFolders}</div>
                      <div className={styles.switchContainer}>
                        <Switch value={isUnfold} onChange={() => setIsUnfold(!isUnfold)} />
                      </div>
                    </>
                  )}
              </div>
              <div>
                {user.orgRole !== 'Viewer' && (
                  <Button
                    variant={'secondary'}
                    icon={'cog'}
                    onClick={() => setIsFiltersMgmtOpen(!isFiltersMgmtOpen)}
                    size={'md'}>
                    {TR_manageMachineTags}
                  </Button>
                )}
              </div>
            </div>
            {/* Default display of folder list */}
            {!selectedFolders.length &&
              !selectedMachines.length &&
              !selectedTags.length &&
              foldersList.length !== 0 &&
              foldersList.map((folder: any, index: number) => (
                <FoldersAndMachines
                  key={folder.value}
                  folder={folder}
                  getTotalDashboardAlerts={getTotalDashboardAlerts}
                  selectedDashboards={dashboardsFiltered}
                  isUnfold={isUnfold}
                />
              ))}
            {/* Display selected folders */}
            {selectedFolders.length !== 0 &&
              selectedFolders.map((folder) => (
                <FoldersAndMachines
                  folder={folder}
                  key={folder.value}
                  getTotalDashboardAlerts={getTotalDashboardAlerts}
                  selectedDashboards={dashboardsFiltered}
                  isUnfold={isUnfold}
                />
              ))}
            {/* Display selected machines outside folder*/}
            {!selectedFolders.length && selectedMachines.length !== 0 && (
              <div className={styles.aloneMachine}>
                {selectedMachines.map((machine) => (
                  <MachineFilter
                    selectedDashboards={dashboardsFiltered}
                    theme={theme}
                    machineFilter={machine}
                    key={machine.value}
                    getTotalDashboardAlerts={getTotalDashboardAlerts}
                    isUnfold={isUnfold}
                  />
                ))}
              </div>
            )}
            {/* Display selected dashboards by tags outside folder and machine */}
            {!selectedFolders.length && !selectedMachines.length && selectedTags.length !== 0 && (
              <div className={styles.aloneTable}>
                <AlertTableContainer selectedDashboards={selectedDashboards} />
              </div>
            )}
            {isFiltersMgmtOpen && (
              <Modal
                className={styles.modalFiltersContainer}
                onClickBackdrop={() => undefined} // Avoid closing modal when clicking outside the modal window
                isOpen={isFiltersMgmtOpen}
                title={
                  <div className={styles.modalTitle}>
                    {TR_syntheticDashboard?.toUpperCase()} - {TR_manageMachineTags}
                  </div>
                }
                onDismiss={() => setIsFiltersMgmtOpen(!isFiltersMgmtOpen)}>
                <div className={styles.modal}>
                  <FiltersManagement />
                </div>
              </Modal>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      max-width: 1440px;
      @media (max-width: 520px) {
        max-width: 520px;
      }
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      user-select: none;
    `,
    container: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      border: 2px solid ${theme.colors.border.weak};
      background-color: ${theme.colors.background.primary};
      padding: 32px 64px;
      @media (max-width: 768px) {
        padding: 12px 24px;
      }
    `,
    dashboardsContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    `,
    switchContainer: css`
      padding: 0px 8px;
      height: 32px;
      display: inline-flex;
      -moz-box-align: center;
      align-items: center;
      border: 1px solid #ccccdc26;
      border-radius: 2px;
      width: 50px;
    `,
    unfoldFeatureContainer: css`
      margin-bottom: 4px;
      padding: 8px;
      display: flex;
      aling-items: end;
      flex-direction: column;
    `,
    headerContainer: css`
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 25px;
      border: 4px solid ${theme.isDark ? '#1f1f20' : '#fbfbfb'};
      @media (max-width: 1260px) {
        justify-content: center;
      }
    `,
    aloneTable: css`
      width: 100%;
      margin-bottom: 20px;
      background-color: ${theme.isDark ? '#424345' : '#ececec'};
      padding: 8px;
    `,
    aloneMachine: css`
      width: 100%;
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    `,
    modalFiltersContainer: css`
      min-height: 95vh;
      margin: auto;
      max-width: 1300px;
      min-width: 900px;
      padding: 10px;
      top: 10px;
      width: 100%;
    `,
    modal: css`
      display: flex;
      flex-direction: column;
      justify-content: center;
    `,
    modalTitle: css`
      padding: 10px 10px 0 10px;
    `,
    loadingContainer: css`
      display: flex;
      justify-content: center;
      width: 100%;
      padding: 40px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    secondaryHeader: css`
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 8px;
      align-items: center;
      height: 73px;
    `,
  };
};
