import { MacAddressProps, MachineFilterProps } from 'app-context/types';
import { PREFIX_MACHINE_FILTER } from 'helpers/constants';
import { DRAGGING_OVER_COLOR } from 'helpers/colors';
import { NotificationError } from 'shared/NotificationMessage';
import { SelectableValue } from '@grafana/data';

/** Sort machine and location filter by alphabetical order */
export const sortFilters = (a: any, b: any) => {
  if (a.name?.toUpperCase() < b.name?.toUpperCase()) {
    return -1;
  }
  if (a.name?.toUpperCase() > b.name?.toUpperCase()) {
    return 1;
  }
  return 0;
};

export const changeFilterEditionStatus = (filterId: string, isEditing: boolean, filterArray: MachineFilterProps[]) => {
  const newFilters = [...filterArray];
  const indexOfFilter = newFilters.findIndex((filter) => filter.id === filterId);
  if (indexOfFilter !== -1) {
    newFilters[indexOfFilter].isEditing = isEditing;
  }
  return newFilters;
};

export const applyNewFilterName = (filterId: string, filterArray: MachineFilterProps[], value: string) => {
  const newFilters = [...filterArray];
  const indexOfFilter = newFilters.findIndex((filter) => filter.id === filterId);

  if (indexOfFilter !== -1) {
    newFilters[indexOfFilter].name = value;
    newFilters[indexOfFilter].isEditing = false;
    newFilters[indexOfFilter].isFilterNameModified = true;
  }
  return newFilters;
};

export const applyFilterDeletion = (
  filters: MachineFilterProps[],
  filtersToDelete: MachineFilterProps[],
  filterId: string
) => {
  const indexOfFilter = filters.findIndex((filter) => filter.id === filterId);
  const newFiltersToDelete = [...filtersToDelete];
  const newFilters = filters.filter((filter) => filter.id !== filterId);
  if (indexOfFilter !== -1) {
    newFiltersToDelete.push(filters[indexOfFilter]);
  }
  return { filters: newFilters, filtersToDelete: newFiltersToDelete };
};

export const applyFilterRestoration = (
  filters: MachineFilterProps[],
  filtersToDelete: MachineFilterProps[],
  filterId: string,
  folders: SelectableValue[]
) => {
  const indexOfFilter = filtersToDelete.findIndex((filter) => filter.id === filterId);

  const machineFiltersToDelete = [...filtersToDelete];
  let newMachineFilters = [...filters];
  const newFiltersToDelete = filtersToDelete.filter((filter) => filter.id !== filterId);

  if (indexOfFilter !== -1) {
    const findIndexOfFilter = folders.findIndex((loc) => loc.value === filtersToDelete[indexOfFilter].folderId);
    if (findIndexOfFilter !== -1) {
      /** Check if filter name already exists in the destination group */
      const isNameInGroup = checkIfNameExistsInMachineGroup(
        newMachineFilters,
        filtersToDelete[indexOfFilter].folderId,
        filtersToDelete[indexOfFilter].name
      );
      if (isNameInGroup) {
        NotificationError({ message: 'a filter with this name already exists in this group' });
        return { filters: newMachineFilters, filtersToDelete: filtersToDelete };
      }
      /** If name doesn't already exist, filter can be restored in its initial location */
      newMachineFilters.unshift(machineFiltersToDelete[indexOfFilter] as MachineFilterProps);
      return { filters: newMachineFilters, filtersToDelete: newFiltersToDelete };
    }
    return { filters: newMachineFilters, filtersToDelete: newFiltersToDelete };
  }
  return { filters: newMachineFilters, filtersToDelete: machineFiltersToDelete };
};

export const getDraggingOverStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? DRAGGING_OVER_COLOR : '',
});

/** Get filter name by its id */
export const findFilterName = (filterId: string, filterArray: MachineFilterProps[]) => {
  if (filterId === '') {
    return filterId;
  }
  const findFilterIndex = filterArray.findIndex((filter) => filter.id === filterId);

  if (findFilterIndex !== -1) {
    return `${PREFIX_MACHINE_FILTER}${filterArray[findFilterIndex].name}`;
  }
  return '';
};

export const handleMacAddressDragged = (
  macAddressDraggedIndex: number,
  macAddresses: MacAddressProps[],
  destinationDroppedId: string[]
): MacAddressProps[] => {
  /** Check if source droppable id is the same as destination droppable id */
  if (destinationDroppedId[1] === macAddresses[macAddressDraggedIndex].machineFilterId) {
    console.log('same destination');
    return macAddresses;
  }
  const newMacAddresses = [...macAddresses];

  if (destinationDroppedId[0] === 'dropMachine') {
    /** Modify the machine id of the mac address dragged */
    newMacAddresses[macAddressDraggedIndex].machineFilterId = destinationDroppedId[1];
  }

  if (destinationDroppedId[0] === 'dropMacAddress') {
    newMacAddresses[macAddressDraggedIndex].machineFilterId = '';
  }
  newMacAddresses[macAddressDraggedIndex].hasBeenMoved = true;
  return newMacAddresses;
};

export const resetEditionFlags = (machineFilters: MachineFilterProps[]) => {
  const newMachineFilters = [...machineFilters];

  newMachineFilters.map((machine) => {
    machine.isAddingFilter = false;
    // machine.isEditing = false;
  });

  return newMachineFilters;
};

export const checkIfNameExistsInMachineGroup = (
  filtersArray: MachineFilterProps[],
  folderId: number,
  value: string
) => {
  const machineFilters = [...filtersArray];
  let destinationFilters = machineFilters.filter((machine) => machine.folderId === folderId);

  if (destinationFilters.length) {
    const isNameInGroup = destinationFilters.find((filter) => filter.name?.toLowerCase() === value?.toLowerCase());
    return isNameInGroup;
  }
  return;
};

export const retrieveMachineFilter = (tagsArray: string[], machinesTags: MachineFilterProps[], folderId: number) => {
  let machineFilter = '';
  for (const tag of tagsArray) {
    if (!tag) {
      continue;
    }
    if (tag?.includes(PREFIX_MACHINE_FILTER)) {
      const splitTag = tag.split('_');
      machineFilter = splitTag[1];
      const sameNameTags = machinesTags.filter((tag) => tag.name === machineFilter);
      if (!sameNameTags.length) {
        machinesTags.push({
          name: machineFilter,
          id: `${machineFilter}-${folderId}`,
          folderId: folderId,
          isEditing: false,
          isFilterNameModified: false,
        });
      } else {
        if (sameNameTags.findIndex((sameName) => sameName.folderId === folderId) === -1) {
          machinesTags.push({
            name: machineFilter,
            id: `${machineFilter}-${folderId}`,
            folderId: folderId,
            isEditing: false,
            isFilterNameModified: false,
          });
        }
      }
    }
  }
  return machineFilter;
};

export const retrieveOtherTags = (tagsArray: string[]) => {
  if (!tagsArray.length) {
    return [];
  }
  const tagsFiltered = tagsArray
    .filter((tag) => !tag.includes(PREFIX_MACHINE_FILTER))
    .map((tag) => ({ label: tag, value: tag }));
  return tagsFiltered;
};

export const removeOneOtherTag = (macAddresses: MacAddressProps[], mac: MacAddressProps, tag: string) => {
  const newMacAddresses = [...macAddresses];
  const macAddressIndex = macAddresses.findIndex((newMac) => newMac.macAddress === mac.macAddress);
  if (macAddressIndex === -1) {
    return [];
  }
  newMacAddresses[macAddressIndex].otherTags = macAddresses[macAddressIndex].otherTags.filter(
    (otherTag) => otherTag.label !== tag
  );
  newMacAddresses[macAddressIndex].isTagsModified = true;
  return newMacAddresses;
};
