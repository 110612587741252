import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Button, Icon, Input, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { AddMachineButton, AddMachineForm, MachineFilter } from '.';
import { MacAddressProps, MachineFilterProps } from '../../app-context/types';
import { getDraggingOverStyle, sortFilters } from './helpersFilters';
import { DraggableMacAddress } from './DraggableMacAddress';

interface FolderContentProps {
  changeFilterName: (filterId: MachineFilterProps, value: string) => void;
  editFilterName: (filterId: string, isEditing: boolean) => void;
  folders: SelectableValue[];
  getDeleteButton: (filterId: string) => JSX.Element | undefined;
  isAddingMachineFilter: boolean;
  isEditingFilters: boolean;
  machineFilters: MachineFilterProps[];
  machinesToDelete: MachineFilterProps[];
  restoreFilter: (filterId: string) => void;
  searchValue: string;
  setIsAddingMachineFilter: (isAdding: boolean) => void;
  setMachineFilters: React.Dispatch<React.SetStateAction<MachineFilterProps[]>>;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  showMacAddresses: boolean;
  showTags: boolean;
  selectedFolder: SelectableValue<any>;
}

export const FolderContent: React.FunctionComponent<FolderContentProps> = ({
  changeFilterName,
  editFilterName,
  folders,
  getDeleteButton,
  isAddingMachineFilter,
  isEditingFilters,
  machineFilters,
  machinesToDelete,
  restoreFilter,
  searchValue,
  setIsAddingMachineFilter,
  setMachineFilters,
  setSearchValue,
  showMacAddresses,
  showTags,
  selectedFolder,
}) => {
  const { state } = useAppState();
  const { dictionary_uiElements, macAddresses } = state;
  const [dashboardList, setDashboardList] = useState<MacAddressProps[]>([]);

  const {
    TR_dashboards,
    TR_dragAndDropDashboards,
    TR_dragAndDropMacAddresses,
    TR_dragMachineHere,
    TR_editFilterName,
    TR_filterDeletedMsg,
    TR_macAddresses,
    TR_restoreFilter,
    TR_search,
  } = dictionary_uiElements;
  const styles = useStyles2(getStyles);

  /** Get button for editing filter name */
  const getEditButton = (filterId: string) => {
    return (
      <Tooltip content={TR_editFilterName} placement={'top'}>
        <div className={styles.iconEditButtons} onClick={() => editFilterName(filterId, true)}>
          <Icon name={'edit'} />
        </div>
      </Tooltip>
    );
  };

  /**  Get list of machine filters associated to folder id */
  const getMachinesInFolder = (folderId: number) => {
    const machinesInGroup = machineFilters.filter((machine) => machine.folderId === folderId);
    if (machinesInGroup.length) {
      return machinesInGroup.sort(sortFilters).map((machine) => {
        if (machine) {
          return (
            <MachineFilter
              changeFilterName={changeFilterName}
              editFilterName={editFilterName}
              filter={machine}
              getDeleteButton={getDeleteButton}
              getEditButton={getEditButton}
              isEditingFilter={isEditingFilters}
              key={`${machine.folderId} - ${machine.id}`}
              showMacAddresses={showMacAddresses}
              showTags={showTags}
            />
          );
        }
        return;
      });
    } else {
      return <div className={styles.noFilterMessage}>{TR_dragMachineHere}</div>;
    }
  };

  /** Drag and drop of mac addresses without machine filter */
  const getDndMacAddresses = () => {
    if (dashboardList.length) {
      return dashboardList.map((mac, idMac) => (
        <DraggableMacAddress
          index={idMac}
          mac={mac}
          position={'left'}
          showMacAddresses={showMacAddresses}
          showTags={showTags}
          key={mac.macAddress}
        />
      ));
    } else {
      return (
        <div className={styles.noFilterMessage}>
          {showMacAddresses ? TR_dragAndDropMacAddresses : TR_dragAndDropDashboards}
        </div>
      );
    }
  };

  const onChangeSearchValue = (e: any) => {
    const macAddressesFiltered = macAddresses.filter(
      (machine) => machine.folderId === folders[0].value && !machine.machineFilterId
    );
    if (!e.target.value) {
      setSearchValue('');
      setDashboardList(macAddressesFiltered);
      return;
    }

    setSearchValue(e.target.value);
    if (showMacAddresses) {
      setDashboardList(macAddressesFiltered.filter((dash) => dash.macAddress.includes(e.target.value)));
    } else {
      setDashboardList(macAddressesFiltered.filter((dash) => dash.dashboardName.includes(e.target.value)));
    }
  };

  useEffect(() => {
    if (macAddresses.length) {
      const macAddressesWithNoFilter = macAddresses.filter(
        (machine) => machine.folderId === folders[0].value && !machine.machineFilterId
      );
      if (searchValue) {
        if (showMacAddresses) {
          setDashboardList(macAddressesWithNoFilter.filter((dash) => dash.macAddress.includes(searchValue)));
          return;
        } else {
          setDashboardList(macAddressesWithNoFilter.filter((dash) => dash.dashboardName.includes(searchValue)));
          return;
        }
      }
      setDashboardList(macAddressesWithNoFilter);
    }
  }, [macAddresses]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Reset search value on switch show mac address
    setSearchValue('');
    setDashboardList(
      macAddresses.filter((machine) => machine.folderId === folders[0].value && !machine.machineFilterId)
    );
  }, [showMacAddresses]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.folderContent} id={`loc_${folders[0].value}`}>
      {/* 
          +-----------------------------------+
          | dashboards  | machine filters     |
          +-----------------------------------+ */}
      {/* LEFT SIDE - Droppable area for dashboards from the selected folder */}
      <div className={styles.leftColumn}>
        <div className={styles.sectionTitle} style={{ marginBottom: '10px' }}>
          {showMacAddresses ? TR_macAddresses?.toUpperCase() : TR_dashboards?.toUpperCase()}
        </div>
        {macAddresses && (
          <Input
            onChange={(e) => onChangeSearchValue(e)}
            placeholder={TR_search}
            prefix={<Icon name={'search'} />}
            suffix={<Icon name={'times'} onClick={(e) => onChangeSearchValue(e)} />}
            value={searchValue}
          />
        )}
        {searchValue && <div className={styles.searchResults}>{dashboardList.length} result(s)</div>}
        {/* Dashboards list  */}
        {dashboardList && (
          //@ts-ignore
          <Droppable droppableId={`dropMacAddress_${folders[0].value}`} type={'MACHINE'}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={styles.dropMacAddress}
                style={getDraggingOverStyle(snapshot.isDraggingOver)}>
                {getDndMacAddresses()}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </div>
      {/* RIGHT SIDE */}
      <div className={styles.rightColumn}>
        <div className={styles.addMachineContainer}>
          {/* Add machine button */}
          <div className={styles.buttonMachineAdd}>
            {isAddingMachineFilter ? (
              <AddMachineForm
                folderId={folders[0].value}
                machineFilters={machineFilters}
                setIsAddingMachineFilter={setIsAddingMachineFilter}
                setMachineFilters={setMachineFilters}
              />
            ) : (
              <AddMachineButton setIsAddingMachineFilter={setIsAddingMachineFilter} />
            )}
          </div>
        </div>
        {/* List of machines in the folder*/}
        <div className={styles.machinesContainer}>{getMachinesInFolder(folders[0].value)}</div>
        {/* List of machines to delete */}
        <div className={styles.machinesContainer}>
          {machinesToDelete?.map((machine) => {
            return (
              <div className={styles.machineRowDeleted} key={`${Math.random() * 2}-${machine.folderId}`}>
                <div className={styles.titleContainer}>
                  <div className={styles.titleMachineDeleted}>{machine.name}</div>
                  <div className={styles.deleteFilterMessage}>{TR_filterDeletedMsg}</div>
                  <div
                    className={css`
                      margin-top: 5px;
                    `}>
                    <Button size={'sm'} variant={'secondary'} onClick={() => restoreFilter(machine.id)}>
                      <Icon name={'arrow-right'} />
                      {TR_restoreFilter}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    machinesContainer: css`
      padding: 4px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
      grid-template-rows: min-content;
      gap: 8px;
    `,
    folderContent: css`
      -moz-box-pack: justify;
      border-radius: 4px;
      display: flex;
      flex: 1 1 0%;
      justify-content: space-between;
      margin: 8px;
      min-width: 350px;
      opacity: 1;
      padding: 16px;
    `,
    buttonMachineAdd: css`
      align-items: center;
      display: flex;
      height: 36px;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 10px;
    `,
    noFilterMessage: css`
      align-items: center;
      display: flex;
      font-size: 18px;
      font-weight: 200;
      height: 100%;
      justify-content: center;
      width: 100%;
    `,
    sectionTitle: css`
      color: ${theme.colors.text.maxContrast};
      font-size: 16px;
      font-weight: 500;
      margin-left: 8px;
    `,
    dropMacAddress: css`
      margin-top: 4px;
      min-height: 60px;
      min-height: 66px;
      padding: 2px;
      padding: 4px;
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1;
    `,
    machineRowDeleted: css`
      background-color: ${theme.isDark ? '#ffeeee3d' : '#ffeeee'};
      border-radius: 4px;
      font-size: 16px;
      font-weight: 500;
      margin: 5px;
      opacity: 1;
      padding: 6px;
    `,
    titleContainer: css`
      border-radius: 2px;
      display: flex;
      flex-direction:column;
      justify-content: space-between;
      margin-bottom: 8px
      margin-left: 8px;
      padding: 2px;
      padding:6px;
      width: 100%;
  `,
    titleMachineDeleted: css`
      align-items: center;
      color: ${theme.colors.text.primary};
      display: flex;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    leftColumn: css`
      background-color: ${theme.isDark ? '#f0f8ff30' : '#effbff'};
      border: 2px dashed #b1d8e3;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 8px;
      max-width: 380px;
    `,
    rightColumn: css`
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      padding: 8px;
      width: 67%;
    `,
    iconEditButtons: css`
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 27px;
      justify-content: center;
      width: 27px;
      &:hover {
        background: white;
        border-radius: 50%;
      }
    `,
    searchResults: css`
      font-style: italic;
      font-size: 12px;
      margin-top: 4px;
      text-align: right;
    `,
    addMachineContainer: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `,
    deleteFilterMessage: css`
      font-style: italic;
      font-size: 12px;
      padding: 4px 0;
    `,
    selectedFolderName: css`
      font-size: 22px;
      font-weight: 500;
      display: flex;
      align-items: flex-end;
      margin-bottom: 8px;
      margin-left: 8px;
    `,
  };
};
