import * as React from 'react';

interface FilterIconProps {
  icon: string;
  size: string;
}

export const FilterIcon: React.FunctionComponent<FilterIconProps> = ({ icon, size }) => {
  return (
    <div style={{ marginRight: '4px' }}>
      <img src={icon} width={size} />
    </div>
  );
};
