/**
 * Colors filters
 */
export const BLUE = '#2C4542;';
export const BG_MACHINE_FILTER = '#effbff';
export const DARK_BLUE_FILTER = '#062d6a';
export const LIGHT_BLUE_FILTER = '#9ec1f7';
export const BG_ZONE_FILTER = '#f6effa';
export const DARK_PURPLE_FILTER = '#4e1d67';
export const LIGHT_PURPLE_FILTER = '#d7b3ea';
export const BLUE_MACHINE_FILTER = '#b1d8e3';
export const DRAGGING_OVER_COLOR = '#f9f4d3';

/** Style */
export const DANGER_ASYSTOM = '#FD7B63';
export const DANGER_ASYSTOM_LIGHT = '#fdcbbb';
export const WARNING_ASYSTOM = '#EEC559';
export const WARNING_ASYSTOM_LIGHT = '#faf1b1';
export const GREEN_ASYSTOM = '#009966';
export const GREEN_ASYSTOM_LIGHT = '#70c8aa';
