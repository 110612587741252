import { getDataSourceSrv } from '@grafana/runtime';
import { NotificationError } from 'shared/NotificationMessage';

const getDatasourceData = async (variables: any[], datasource: string) => {
  const dsTemplate: any = variables && variables.filter((template) => template.name === datasource);

  if (!dsTemplate || !dsTemplate?.length) {
    return;
  }

  const datasourceData = await getDataSourceSrv()
    .get(dsTemplate[0]?.current.value)
    .catch((err) => {
      NotificationError(err);
      console.log(err);
      return;
    });

  return datasourceData;
};

export const getDatasources = async (grafanaVariables: any[]) => {
  const mysqlDs = await getDatasourceData(grafanaVariables, 'mysql').catch((err) => NotificationError(err));
  const influxDs = await getDatasourceData(grafanaVariables, 'influx').catch((err) => NotificationError(err));
  const jsonDs = await getDataSourceSrv().get('DashGen');

  if (!mysqlDs || !influxDs || !jsonDs) {
    return;
  }

  return {
    mysql: { name: mysqlDs.name, uid: mysqlDs.uid, id: mysqlDs.id },
    influx: { name: influxDs.name, uid: influxDs.uid, id: influxDs.id },
    json: { name: jsonDs.name, uid: jsonDs.uid, id: jsonDs.id },
  };
};

export const getDatasourceQueryUrl = (query: string, datasourceInflux: { uid: string; name: string }) =>
  `api/datasources/proxy/uid/${datasourceInflux?.uid}/query?db=${datasourceInflux.name}&q=${query}`;
