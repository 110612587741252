// src/Table.js
import React, { useEffect, useState } from 'react';
import { useTable, useFilters, useGlobalFilter, usePagination, useSortBy, useExpanded } from 'react-table';
import { getAlertTableStyles } from './alertTable.styles';
import { useAppState } from 'app-context/AppStateContext';
import { getStateDisplayModel } from 'helpers/helpersFilters';
import { css } from '@emotion/css';
import { useStyles2, Icon } from '@grafana/ui';
import { AlertDetailsModal } from '../alert-details/AlertDetailsModal';
import { buildCustomCells } from './alertTableHelpers';

interface TableProps {
  columns: any[];
  data: any[];
}

export const AlertsTable: React.FunctionComponent<TableProps> = ({ columns, data }) => {
  const { state: appState } = useAppState(); // state is here renamed because react-table also uses a 'state' namespace
  const { dictionary_uiElements } = appState;
  const { TR_dashboard } = dictionary_uiElements;

  const [isVisible, setIsVisible] = useState(false);
  const [cellInfo, setCellInfo] = useState({ details: {} as any, cellIndex: null });

  const styles = useStyles2(getAlertTableStyles);
  const stylesEmotion = useStyles2(getStyles);
  const getCellProps = (cellInfo: any) => buildCustomCells(cellInfo, dictionary_uiElements);

  const openModal = (cell: any, cellIndex: any) => {
    setIsVisible(!isVisible);
    if (!isVisible) {
      setCellInfo({ details: cell, cellIndex: cellIndex });
    }
  };

  // Create a default prop getter
  const defaultPropGetter = () => ({});

  const {
    canNextPage,
    canPreviousPage,
    getTableBodyProps,
    getTableProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    pageOptions,
    prepareRow,
    previousPage,
    setPageSize,
    state,
  }: any = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetPage: false,
      initialState: {
        hiddenColumns: [],
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  useEffect(() => {
    if (cellInfo.cellIndex !== null && page) {
      const pageIndex = page.findIndex((p: { index: number }) => p.index === cellInfo?.details?.row?.index);
      if (pageIndex !== -1) {
        setCellInfo({ ...cellInfo, details: page[pageIndex]?.cells[cellInfo.cellIndex] });
      }
    }
  }, [data, page]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.tableWrap}>
      <table {...getTableProps()} style={{ width: '100%' }}>
        <thead className={styles.thead}>
          {headerGroups.map((headerGroup: any, indexGroupHeaders: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={indexGroupHeaders}>
              {headerGroup.headers.map((column: any, indexHeaderGroupHeader: number) => (
                <th {...column.getHeaderProps()} className={styles.rowHeader} key={indexHeaderGroupHeader}>
                  <div>{column.render('Header')}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row: any, indexRow: number) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className={styles.row} key={Math.random() * (2.5 - 1.5) + 1.5}>
                {row?.cells?.map((cell: any, indexCell: number) => {
                  return (
                    <td
                      {...cell.getCellProps([defaultPropGetter(), getCellProps(cell)])}
                      className={styles.tableCellWrapper}
                      key={Math.random() * (2.5 - 1.5) + 1.5}>
                      {/* Display mac address */}
                      {cell && cell.column.Header?.toUpperCase() === TR_dashboard?.toUpperCase() ? (
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                          {/* Dashboard name and mac address */}
                          <div
                            onClick={() => (cell?.value && cell?.value[1] ? window.open(cell?.value[1]) : null)}
                            style={{ cursor: 'pointer' }}>
                            {cell?.value && cell?.value[0]}{' '}
                            <span className={stylesEmotion.macAddress}>
                              {cell?.value && cell?.value[2]?.toUpperCase()}
                            </span>
                          </div>
                          {/* Dashboard tags */}
                          <div>
                            {cell?.value &&
                              cell?.value[3]?.map((tag: string, indexTag: number) => (
                                <div className={stylesEmotion.badge} key={indexTag}>
                                  <Icon name={'tag-alt'} size={'xs'} style={{ marginRight: '2px' }} />
                                  {tag}
                                </div>
                              ))}
                          </div>
                        </div>
                      ) : (
                        cell?.value && (
                          <div onClick={() => openModal(cell, indexCell)}>
                            <Icon name={getStateDisplayModel(cell?.value[1]).iconClass} size={'xl'} />
                          </div>
                        )
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {isVisible && <AlertDetailsModal cellInfo={cellInfo} openModal={openModal} />}
      {data.length > 10 && (
        <Pagination
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          gotoPage={gotoPage}
          nextPage={nextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          previousPage={previousPage}
          setPageSize={setPageSize}
          state={state}
        />
      )}
    </div>
  );
};

interface PaginationProps {
  canNextPage: any;
  canPreviousPage: any;
  gotoPage: any;
  nextPage: any;
  pageCount: any;
  pageOptions: any;
  previousPage: any;
  setPageSize: any;
  state: any;
}

const Pagination: React.FunctionComponent<PaginationProps> = ({
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  pageCount,
  pageOptions,
  previousPage,
  setPageSize,
  state,
}) => {
  const styles = useStyles2(getStyles);
  const paginationButtons = [
    { buttonContent: '<<', buttonDisabled: !canPreviousPage, buttonOnClick: () => gotoPage(0) },
    { buttonContent: '<', buttonDisabled: !canPreviousPage, buttonOnClick: () => previousPage() },
    { buttonContent: '>', buttonDisabled: !canNextPage, buttonOnClick: () => nextPage() },
    { buttonContent: '>>', buttonDisabled: !canNextPage, buttonOnClick: () => gotoPage(pageCount - 1) },
  ];

  return (
    <div className="pagination">
      {paginationButtons.map((el) => (
        <button
          onClick={el.buttonOnClick}
          disabled={el.buttonDisabled}
          className={styles.buttonPagination}
          key={Math.random() * (2.5 - 1.5) + 1.5}>
          {el.buttonContent}
        </button>
      ))}
      <span>
        Page{' '}
        <strong>
          {state.pageIndex + 1} of {pageOptions.length}
        </strong>{' '}
      </span>
      <select
        value={state.pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}>
        {[5, 10, 15, 20].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};

const getStyles = () => {
  return {
    buttonPagination: css`
      border-radius: 4px;
      border: 1px solid;
      border-color: #bebebe;
    `,
    badge: css`
      font-size: 12px;
      display: inline-flex;
      padding: 1px 4px;
      border-radius: 3px;
      background: #e5eefd;
      border: 1px solid #9ec1f7;
      color: #093374;
      margin-left: 6px;
    `,
    macAddress: css`
      font-size: 12px;
      font-weight: 400;
      display: inline;
      @media (max-width: 861px) {
        display: none;
      }
    `,
  };
};
