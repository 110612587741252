import { Icon, useStyles2, Tooltip } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';

import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { FilterLoading, FilterTopMenu, FolderContent } from '.';
import { GrafanaTheme2 } from '@grafana/data';
import { useFiltersManagement } from 'hooks/useFiltersManagement';

interface FiltersContainersProps {}

export const FiltersManagement: React.FunctionComponent<FiltersContainersProps> = () => {
  const [showMacAddresses, setShowMacaddresses] = React.useState(false);
  const [showTags, setShowTags] = React.useState(false);
  const [isAddingMachineFilter, setIsAddingMachineFilter] = React.useState(false);

  const { state } = useAppState();
  const { dictionary_uiElements, selectedFolder } = state;

  const {
    applyNewFilters,
    changeFilterName,
    deleteFilter,
    editFilterName,
    folders,
    isApplyingChanges,
    isEditingFilters,
    isLoadingFilters,
    macAddresses,
    machinesToDelete,
    machineFilters,
    onDragEnd,
    reset,
    restoreFilter,
    searchValue,
    setIsEditingFilters,
    setMachineFilters,
    setSearchValue,
  } = useFiltersManagement();

  /** Traduction */
  const { TR_applyingChanges, TR_deleteFilter, TR_loadingFilters } = dictionary_uiElements;

  const styles = useStyles2(getStyles);

  /** Get button for deleting machine filters */
  const getDeleteButton = (filterId: string) => {
    const macAddressesListInMachines = macAddresses.filter((address) => address.machineFilterId === filterId);
    if (!macAddressesListInMachines.length) {
      return (
        <div style={{ color: '#e11c1c' }}>
          <Tooltip content={TR_deleteFilter} placement={'top'}>
            <div className={styles.iconEditButtons} onClick={() => deleteFilter(filterId)}>
              <Icon name={'trash-alt'} size={'lg'} />
            </div>
          </Tooltip>
        </div>
      );
    }
    return;
  };

  return (
    <div className={styles.mainContainer}>
      {/* Title and buttons (in horizontal top section) */}
      <FilterTopMenu
        reset={reset}
        applyNewFilters={applyNewFilters}
        setIsEditingFilterName={setIsEditingFilters}
        isEditingFilterName={isEditingFilters}
        showMacAddresses={showMacAddresses}
        setShowMacAddresses={setShowMacaddresses}
        showTags={showTags}
        setShowTags={setShowTags}
        folders={folders}
        selectedFolder={selectedFolder}
      />
      <div
        className={css`
          display: flex;
          min-height: 65vh;
        `}>
        <div className={styles.bottomContainer}>
          {isApplyingChanges && <FilterLoading text={TR_applyingChanges} />}
          {isLoadingFilters && <FilterLoading text={TR_loadingFilters} />}
          {!isLoadingFilters && !isApplyingChanges && (
            <div className={styles.wrapperFilters}>
              {/* Folder content (dashboards and machine */}
              <div className={styles.foldersContent}>
                {Object.keys(selectedFolder).length && folders.length !== 0 && (
                  // @ts-ignore
                  <DragDropContext onDragEnd={onDragEnd} key={`${selectedFolder.value}-${selectedFolder?.label}`}>
                    <FolderContent
                      changeFilterName={changeFilterName}
                      editFilterName={editFilterName}
                      folders={folders.filter((folder) => folder.value === selectedFolder.value)}
                      getDeleteButton={getDeleteButton}
                      isAddingMachineFilter={isAddingMachineFilter}
                      isEditingFilters={isEditingFilters}
                      key={`${selectedFolder.value}-${selectedFolder?.label}`}
                      machineFilters={machineFilters}
                      machinesToDelete={machinesToDelete.filter((machine) => machine.folderId === selectedFolder.value)}
                      restoreFilter={restoreFilter}
                      searchValue={searchValue}
                      setIsAddingMachineFilter={setIsAddingMachineFilter}
                      setMachineFilters={setMachineFilters}
                      setSearchValue={setSearchValue}
                      showMacAddresses={showMacAddresses}
                      showTags={showTags}
                      selectedFolder={selectedFolder}
                    />
                  </DragDropContext>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    mainContainer: css`
      display: flex;
      flex-direction: column;
      min-height: 600px;
    `,
    iconEditButtons: css`
      align-items: center;
      cursor: pointer;
      display: flex;
      height: 27px;
      justify-content: center;
      width: 27px;
      &:hover {
        background: white;
        border-radius: 50%;
      }
    `,
    bottomContainer: css`
      display: flex;
      flex-direction: column;
      width: 100%;
    `,
    wrapperFilters: css`
      height: 100%;
      width: 100%;
    `,
    foldersContent: css`
      background-color: ${theme.isDark ? theme.colors.background.secondary : theme.colors.background.primary};
      border-bottom: 1px solid ${theme.colors.border.medium};
      border-left: 1px solid ${theme.colors.border.medium};
      border-right: 1px solid ${theme.colors.border.medium};
      max-height: 60vh;
      overflow-y: auto;
      height: 100%;
    `,
  };
};
