import { css, keyframes } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

export interface AlertTableStyles {
  flexColumn: string;
  element: string;

  tableWrap: string;
  cellHeight: number;
  cellHeightInner: number;
  cellPadding: number;
  rowHeight: number;
  table: string;
  thead: string;
  headerCell: string;
  headerCellLabel: string;
  tableCell: string;
  tableCellWrapper: string;
  tableCellLink: string;
  row: string;
  rowHeader: string;
}

export const getAlertTableStyles = (theme: GrafanaTheme2): AlertTableStyles => {
  const { colors } = theme;
  const padding = 6;
  const lineHeight = 1.3;
  const bodyFontSize = 14;
  // const scollbarWidth = getScrollbarWidth();

  const cellHeight = padding * 2.5 + bodyFontSize * lineHeight;

  return {
    cellHeight,
    cellPadding: padding,
    cellHeightInner: bodyFontSize * lineHeight,
    rowHeight: cellHeight + 2,
    tableWrap: css`
      display: block;
      max-width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
    `,
    table: css`
      height: 100%;
      width: 100%;
      overflow: auto;
      display: flex;
    `,
    thead: css`
      height: ${cellHeight}px;
      overflow-y: auto;
      overflow-x: hidden;
      position: relative;
      font-size: 1.2rem;
      text-align: center;
    `,
    headerCell: css`
      border: 1px solid ${colors.border.medium};
      color: ${colors.text.secondary};
      cursor: pointer;
      font-size: 1.1rem;
      height: 55px;
      overflow: hidden;
      padding: ${padding}px;
      text-align: center;
      white-space: nowrap;
    `,
    headerCellLabel: css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `,
    row: css`
      border-top: 2px solid ${colors.background.primary};
      font-size: 14px;
      padding: 4px;
    `,
    rowHeader: css`
      text-align: center;
      font-size: 13px;
      &:first-child {
        > div {
          text-align: left;
          margin-left: 4px;
        }
      }
    `,
    tableCellWrapper: css`
      margin-bottom: 3px;
      padding: 5px;
      text-align: center;
      width: auto;

      &:first-child {
        > div {
          text-align: left;
        }
      }

      &:last-child {
        > div {
          padding-right: ${padding}px;
        }
      }
    `,
    tableCellLink: css`
      text-decoration: underline;
    `,
    tableCell: css`
      padding: ${padding}px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &:hover {
        opacity: 0.5;
      }
    `,

    flexColumn: css`
      display: flex;
      flex-direction: column;
    `,
    element: keyframes`
      0% {
        opacity: 0.1;
      }
      100% {
        opacity: 1;
      } 
      `,
  };
};
