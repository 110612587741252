import { AppState, Action } from './types';

/**
 * Contains all the state actions
 */

export const appStateReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'SET_DASHBOARDS_WITH_ALERTS': {
      return {
        ...state,
        dashboardsWithAlerts: action.payload,
      };
    }

    case 'SET_SELECTED_DASHBOARDS': {
      return {
        ...state,
        selectedDashboards: action.payload,
      };
    }

    case 'SET_DATASOURCES': {
      return {
        ...state,
        datasourceMysql: action.payload.mysql,
        datasourceInflux: action.payload.influx,
        datasourceJson: action.payload.json,
      };
    }

    case 'SET_SELECTED_MACHINES': {
      return {
        ...state,
        selectedMachines: action.payload,
      };
    }

    case 'SET_MACHINE_FILTERS': {
      return {
        ...state,
        machineFilters: action.payload,
      };
    }

    case 'ADD_TRANSLATED_UI_TO_DICO': {
      return {
        ...state,
        dictionary_uiElements: action.payload,
      };
    }

    case 'SET_ALERTS_COLUMNS': {
      return {
        ...state,
        alertsColumns: action.payload,
      };
    }

    case 'SET_TAGS': {
      return {
        ...state,
        tags: action.payload,
      };
    }

    case 'SET_SELECTED_TAGS': {
      return {
        ...state,
        selectedTags: action.payload,
      };
    }

    case 'SET_SELECTED_FOLDERS': {
      return {
        ...state,
        selectedFolders: action.payload,
      };
    }

    case 'SET_SELECTED_STATUS': {
      return {
        ...state,
        selectedStatus: action.payload,
      };
    }

    // Filters management
    case 'SET_MAC_ADDRESSES': {
      return {
        ...state,
        macAddresses: action.payload,
      };
    }

    case 'SET_SELECTED_FOLDER': {
      return {
        ...state,
        selectedFolder: action.payload,
      };
    }

    case 'SET_PAUSE_TRIGGER': {
      return {
        ...state,
        pauseTrigger: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
