import React from 'react';
import { Icon, useStyles2, IconName } from '@grafana/ui';
import { css } from '@emotion/css';

interface CountAlertsHeaderProps {
  countAlerts: {
    alerting: number;
    noData: number;
    ok: number;
    paused: number;
    unknown: number;
  };
  selectedStatus: {
    ok: boolean;
    noData: boolean;
    alerting: boolean;
    paused: boolean;
  };
}

export const CountAlertsHeader: React.FunctionComponent<CountAlertsHeaderProps> = ({ countAlerts, selectedStatus }) => {
  const styles = useStyles2(getStyles);

  const countStatuses = [
    {
      status: selectedStatus.alerting,
      color: 'red',
      count: countAlerts.alerting,
      icon: 'exclamation-triangle' as IconName,
      text: 'ALERTING',
    },
    {
      status: selectedStatus.noData,
      color: 'orange',
      count: countAlerts.noData,
      icon: 'question-circle' as IconName,
      text: 'NO DATA',
    },
    {
      status: selectedStatus.ok,
      color: 'green',
      count: countAlerts.ok,
      icon: 'check-circle' as IconName,
      text: 'OK',
    },
    {
      status: selectedStatus.paused,
      color: 'gray',
      count: countAlerts.paused,
      icon: 'pause' as IconName,
      text: 'PAUSED',
    },
  ];

  return (
    <div className={styles.container}>
      {countStatuses.map(
        (countStatus) =>
          countStatus?.status && (
            <div className={styles.counterContainer} key={countStatus.text}>
              <div style={{ color: countStatus.color, opacity: countStatus.count === 0 ? 0.3 : 1, fontWeight: 500 }}>
                {countStatus.text}
              </div>
              <div
                className={styles.alert}
                style={{ color: countStatus.color, opacity: countStatus.count === 0 ? 0.3 : 1 }}>
                <Icon name={countStatus.icon} className={styles.icon} />
                <div>{countStatus.count}</div>
              </div>
            </div>
          )
      )}
    </div>
  );
};

const getStyles = () => {
  return {
    container: css`
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      @media (max-width: 1300px) {
        justify-content: center;
      }
    `,
    alert: css`
      padding: 3px;
      font-weight: 500;
      display: flex;
      align-items: center;
      font-size: 64px;
      @media (max-width: 520px) {
        font-size: 42px;
      }
    `,
    counterContainer: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 12px;
    `,
    icon: css`
      margin-right: 3px;
      width: 48px;
      height: auto;
      @media (max-width: 520px) {
        width: 32px;
      }
    `,
  };
};
