import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Button, Icon, Select, useStyles2, Switch } from '@grafana/ui';
import { useAppState } from 'app-context/AppStateContext';
import { css } from '@emotion/css';
import * as React from 'react';
import { BG_MACHINE_FILTER, BLUE } from 'helpers/colors';

interface FilterTopMenuProps {
  isEditingFilterName: boolean;
  reset: () => void;
  setIsEditingFilterName: (value: boolean) => void;
  showMacAddresses: boolean;
  showTags: boolean;
  setShowMacAddresses: (value: boolean) => void;
  setShowTags: (value: boolean) => void;
  applyNewFilters: () => void;
  folders: any[];
  selectedFolder: any;
}

export const FilterTopMenu: React.FunctionComponent<FilterTopMenuProps> = ({
  applyNewFilters,
  folders,
  reset,
  selectedFolder,
  setShowMacAddresses,
  setShowTags,
  showMacAddresses,
  showTags,
}) => {
  const { state } = useAppState();
  const { dictionary_uiElements, theme } = state;
  const { TR_applyButton, TR_pluginSubTitle, TR_reset, TR_filtersMachine, TR_showMacAddresses, TR_showTags } =
    dictionary_uiElements;
  const styles = useStyles2(getStyles);

  return (
    <div
      className={css`
        display: flex;
        width: 100%;
        flex-direction: column;
      `}>
      {/* First line */}
      <div className={styles.firstLine}>
        {/* Icon and title */}
        <div
          className={css`
            width: 100%;
            display: flex;
            color: ${theme.isDark ? BG_MACHINE_FILTER : `${BLUE}`};
          `}>
          <Icon name={'cog'} size="xxl" className={styles.icon} />
          <div
            className={css`
              display: flex;
              flex-direction: column;
            `}>
            <div className={styles.title}>{TR_filtersMachine?.toUpperCase()}</div>
            <div>{TR_pluginSubTitle}</div>
          </div>
        </div>
        {/* Reset and apply buttons */}
        <div className={styles.applyContainer}>
          <div
            className={css`
              margin-right: 6px;
            `}>
            <Button onClick={reset} variant={'secondary'} fill={'text'} icon={'history'} size={'md'}>
              {TR_reset?.toUpperCase()}
            </Button>
          </div>
          <Button onClick={() => applyNewFilters()} variant={'secondary'} icon={'check'} size={'md'}>
            {TR_applyButton?.toUpperCase()}
          </Button>
        </div>
      </div>
      {/* Second line */}
      <div className={styles.menuContainer}>
        {/* Folder selection */}
        <FolderSelection folders={folders} selectedFolder={selectedFolder} />
        <div className={styles.buttonsContainer}>
          <SwitchButton
            valueToSwitch={showMacAddresses}
            actionSwitch={setShowMacAddresses}
            switchText={TR_showMacAddresses?.toLowerCase()}
            theme={theme}
          />
          <SwitchButton
            valueToSwitch={showTags}
            actionSwitch={setShowTags}
            switchText={TR_showTags?.toLowerCase()}
            theme={theme}
          />
        </div>
      </div>
    </div>
  );
};

interface SwitchButtonProps {
  valueToSwitch: boolean;
  actionSwitch: (value: boolean) => void;
  switchText: string;
  theme: GrafanaTheme2;
}

const SwitchButton: React.FunctionComponent<SwitchButtonProps> = ({
  valueToSwitch,
  actionSwitch,
  switchText,
  theme,
}) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.switchContainer} style={{ background: theme.colors.background.canvas }}>
      <div className={styles.switchButton}>{switchText}</div>
      <Switch value={valueToSwitch} onChange={() => actionSwitch(!valueToSwitch)} />
    </div>
  );
};

interface FolderSelectionProps {
  folders: SelectableValue[];
  selectedFolder: SelectableValue;
}

const FolderSelection: React.FunctionComponent<FolderSelectionProps> = ({ folders, selectedFolder }) => {
  const { state, dispatch } = useAppState();
  const { dictionary_uiElements } = state;
  const { TR_folderPlaceholder } = dictionary_uiElements;

  const onChangeFolder = (e: SelectableValue) => {
    dispatch({ type: 'SET_SELECTED_FOLDER', payload: e });
  };

  return (
    <div>
      <Select
        options={folders}
        placeholder={TR_folderPlaceholder}
        value={selectedFolder}
        onChange={(e) => onChangeFolder(e)}
        width={50}
        prefix={<Icon name={'folder-open'} />}
      />
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    firstLine: css`
      display: flex;
      margin-bottom: 24px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    `,
    menuContainer: css`
      background: ${theme.colors.background.secondary};
      border: 1px solid black;
      display: flex;
      flex-direction: row;
      padding: 8px 10px;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    `,
    buttonsContainer: css`
      align-items: stretch;
      display: flex;
    `,
    switchContainer: css`
      align-items: center;
      border-radius: 3px;
      color: #2c3035;
      display: flex;
      justify-content: space-between;
      margin-left: 8px;
      padding: 8px;
      width: 240px;
    `,
    title: css`
      display: flex;
      font-size: 22px;
      font-weight: 600;
    `,
    icon: css`
      margin: 5px;
    `,
    applyContainer: css`
      display: flex;
      justify-content: flex-end;
      width: 100%;
    `,
    switchButton: css`
      font-size: 14px;
      font-weight: 500;
      marginleft: 8px;
    `,
    labelSelect: css`
      font-weight: 500;
      color: #ffffff;
    `,
  };
};
