// src/App.js
import React, { useState, useEffect } from 'react';
import { AlertsTable } from './AlertsTable';
import { useAppState } from '../../app-context/AppStateContext';
import { DashboardWithAlerts } from 'app-context/types';
import { dataToTableDisplay } from './alertTableHelpers';

interface TableProps {
  selectedDashboards: DashboardWithAlerts[];
}

export const AlertTableContainer: React.FunctionComponent<TableProps> = ({ selectedDashboards }) => {
  const { state } = useAppState();
  const { lang, alertsColumns, dictionary_uiElements } = state;
  const { TR_dashboard } = dictionary_uiElements;

  const [dashoardColumns, setDashboardsColumns] = useState([] as any);
  const [dashboardData, setDashboardData] = useState([] as any[]);

  const getLabels = () => {
    /** Get all alert names to build column headers of each table */
    const alerts = selectedDashboards.map((dashboard) => dashboard.alerts);
    const labels = alerts.flat().reduce((acc, obj: any, index: number) => {
      if (index === 0) {
        acc.push(obj.name?.toUpperCase());
        return acc;
      }

      if (acc.indexOf(obj.name?.toUpperCase()) === -1) {
        acc.push(obj.name?.toUpperCase());
        return acc;
      }

      return acc;
    }, [] as string[]);
    return labels;
  };

  const getData = () => {
    const formatDashboardData = dataToTableDisplay(selectedDashboards, lang);
    setDashboardData(formatDashboardData);
  };

  const getColumns = () => {
    const dashboardNameHeader = { Header: TR_dashboard?.toUpperCase(), accessor: 'dashboardName' };

    const labels = getLabels();
    const dashboardColumns = [];
    for (const label of labels) {
      const indexOfColumn = alertsColumns.findIndex((column) => column.Header?.toUpperCase() === label?.toUpperCase());
      if (indexOfColumn !== -1 && alertsColumns[indexOfColumn].isChecked) {
        dashboardColumns.push({
          Header: label?.toUpperCase(),
          accessor: label.replace(/\s/g, '-').toLowerCase(),
        });
      }
    }
    setDashboardsColumns([dashboardNameHeader, ...dashboardColumns]);
  };

  useEffect(() => {
    if (selectedDashboards.length) {
      getColumns();
      getData();
    }
  }, [alertsColumns, selectedDashboards]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns = React.useMemo(() => dashoardColumns, [dashoardColumns]);
  const data = React.useMemo(() => dashboardData, [dashboardData, selectedDashboards]); // eslint-disable-line react-hooks/exhaustive-deps

  return <AlertsTable columns={columns} data={data} />;
};
